import React, { useState, useEffect } from "react";
import { ResultsContent } from "../organisms/ResultsContent";
import { QuestionContainer } from "../organisms/QuestionContainer";
import { useHistory, useParams } from "react-router-dom";

import { getParticipantByUid } from "../xyzFirebase/firebaseQueries";
import firebase from "firebase/app";
import "firebase/auth";

export function ResultsComponent(properties) {
  const [isQuestionAnswered, setIsQuestionAnswered] = useState(undefined);
  const [givenAnswerLabel, setGivenAnswerLabel] = useState();

  let params = useParams();
  let history = useHistory();

  useEffect(() => {
    if (firebase.auth().currentUser !== null) {
      getParticipantByUid(firebase.auth().currentUser.uid).then((doc) => {
        if (doc[0].data[params.questionid] !== undefined) {
          setIsQuestionAnswered(true);
          setGivenAnswerLabel(`response${doc[0].data[params.questionid]}Copy`);
        } else {
          setIsQuestionAnswered(false);
        }
      });
    } else {
      setIsQuestionAnswered(false);
    }
  }, [params.questionid]);

  if (isQuestionAnswered === undefined) {
    return null;
  }

  if (isQuestionAnswered === false) {
    history.push(`/question/${properties.id}`);
    window.scrollTo(0, 0);
  }

  if (isQuestionAnswered !== false) {
    return (
      <QuestionContainer id={properties.id}>
        {(questionData) => {
          return (
            <>
              <ResultsContent
                question={questionData}
                id={properties.id}
                givenAnswerLabel={givenAnswerLabel}
              />
            </>
          );
        }}
      </QuestionContainer>
    );
  } else return <></>;
}
