import React, { useEffect, useState } from "react";
import { useHistory } from "react-router-dom";
import styled from "styled-components";
import { FormNavigationComponent } from "../molecules/FormNavigation";
import { ChangeOverlay } from "../organisms/ChangeOverlay";
import { getParticipantByUid } from "../xyzFirebase/firebaseQueries";
import firebase from "firebase/app";
import "firebase/auth";
import Cookies from "js-cookie";
// import Cookies from "js-cookie";

const Content = styled.div`
  width: 100%;
  display: flex;
  justify-content: center;
  padding-bottom: 32px;
`;

const Container = styled.div`
  @media (min-width: 449px) {
    margin-top: 70px;
    margin-bottom: 0px;
    width: 100%;
    height: 100%;
    margin-left: 16px;
    margin-right: 16px;
    max-width: 992px;
    height: fit-content;
    display: flex;
    flex-direction: column;
    justify-content: center;
  }

  @media (max-width: 448px) {
    margin-top: 70px;
    margin-bottom: 0px;
    margin-left: 16px;
    margin-right: 16px;
    width: 100%;
    height: 100%;
    max-width: 992px;
    height: fit-content;
    display: flex;
    flconstex-direction: column;
    align-items: flex-start;
    justify-content: center;
  }
`;

const Section = styled.div`
  @media (min-width: 449px) {
    width: 100%;
    display: flex;
    flex-direction: column;
    justify-content: center;
    margin-bottom: 64px;
  }

  @media (max-width: 448px) {
    width: 100%;
    display: flex;
    flex-direction: column;
    justify-content: center;
    margin-bottom: 32px;
  }
`;

const SubHeader = styled.h3`
  margin-bottom: 16px;
`;

const DetailsContainer = styled.div`
  margin-bottom: 16px;
`;

const Group = styled.div`
  margin-bottom: 16px;
`;

const Label = styled.label``;

const Value = styled.p`
  margin: 0;
`;

const Link = styled.a`
  text-decoration: underline;
`;

export function Account(properties) {
  //raw values
  const [gender, setGender] = useState();
  const [ageRange, setAgeRange] = useState();
  const [educationLevel, setEducationLevel] = useState();

  // Friendly values
  const [educationFriendly, setEducationFriendly] = useState();
  const [genderFriendly, setGenderFriendly] = useState();
  const [ageRangeFriendly, setAgeRangeFriendly] = useState();

  const [overlayVisibility, setOverlayVisibility] = useState(false);
  const [genderChange, setGenderChange] = useState(false);
  const [ageRangeChange, setAgeRangeChange] = useState(false);
  const [educationLevelChange, setEducationLevelChange] = useState(false);
  // const [isdataAvailable, setIsDataAvaialable] = useState();

  let history = useHistory();

  useEffect(() => {
    if (firebase.auth().currentUser === null) {
      history.push("/log-in");
      window.scrollTo(0, 0);
    } else {
      Cookies.set("isLoggedIn", "true", { sameSite: "strict" });
    }
  });

  useEffect(() => {
    if (firebase.auth().currentUser != null) {
      getParticipantByUid(firebase.auth().currentUser.uid).then((doc) => {
        if (doc !== undefined) {
          if (doc[0].data.gender !== undefined) {
            setGender(doc[0].data.gender);
          }
          if (doc[0].data.ageGroup !== undefined) {
            setAgeRange(doc[0].data.ageGroup);
          }
          if (doc[0].data.educationGroup !== undefined) {
            setEducationLevel(doc[0].data.educationGroup);
          }
          // setIsDataAvaialable(true);
        }
      });
    }
  }, []);

  useEffect(() => {
    switch (educationLevel) {
      case "D":
        setEducationFriendly("Secondary school");
        break;
      case "C":
        setEducationFriendly("Sixth form / College");
        break;
      case "B":
        setEducationFriendly("Bachelor's degree");
        break;
      case "A":
        setEducationFriendly("Masters degree / PhD");
        break;
      default:
        setEducationFriendly("Unknown");
    }

    switch (ageRange) {
      case "1":
        setAgeRangeFriendly("18-25 years old");
        break;
      case "2":
        setAgeRangeFriendly("26-30 years old");
        break;
      case "3":
        setAgeRangeFriendly("31-39 years old");
        break;
      case "4":
        setAgeRangeFriendly("40+");
        break;
      default:
        setAgeRangeFriendly("Unknown");
    }

    switch (gender) {
      case "female":
        setGenderFriendly("Female");
        break;
      case "male":
        setGenderFriendly("Male");
        break;
      case "non-binary":
        setGenderFriendly("Non Binary");
        break;
      default:
        setGenderFriendly("unknown");
    }
  }, [educationLevel, gender, ageRange]);

  useEffect(() => {
    if (genderChange || ageRangeChange || educationLevelChange) {
      setOverlayVisibility(true);
    }
  }, [genderChange, ageRangeChange, educationLevelChange]);

  function logOut() {
    firebase
      .auth()
      .signOut()
      .then(() => {
        // Sign-out successful.
        console.log("You've logged out");
        Cookies.remove("isLoggedIn");
        properties.hasLoggedInFunction();
        history.push(window.localStorage.getItem("lastLocation"));
      })
      .catch(function (error) {
        // An error happened.
      });
  }

  function back() {
    history.push(window.localStorage.getItem("lastLocation"));
  }

  function changeGender() {
    setGenderChange(true);
  }

  function changeAge() {
    setAgeRangeChange(true);
  }

  function changeEducationLevel() {
    setEducationLevelChange(true);
  }

  function hideOverlay() {
    setEducationLevelChange(false);
    setGenderChange(false);
    setAgeRangeChange(false);
    setOverlayVisibility(false);
    getParticipantByUid(firebase.auth().currentUser.uid).then((doc) => {
      setGender(doc[0].data.gender);
      setAgeRange(doc[0].data.ageGroup);
      setEducationLevel(doc[0].data.educationGroup);
      // setIsDataAvaialable(true);
    });
  }

  firebase.auth().onAuthStateChanged(function (user) {
    if (user) {
      // User is signed in.
    } else {
      // No user is signed in.
    }
  });

  function onShowOveray() {
    if (overlayVisibility === true) {
      return (
        <ChangeOverlay
          toHideOverlay={hideOverlay}
          showGender={genderChange}
          showAgeGroup={ageRangeChange}
          showEducationLevel={educationLevelChange}
        />
      );
    } else {
      return null;
    }
  }

  return (
    <Content>
      <Container>
        {onShowOveray()}
        <Section>
          <h1>Account</h1>
          <DetailsContainer>
            <SubHeader>You account details</SubHeader>
            <Group>
              <Label>Gender</Label>
              <Value>
                {genderFriendly} <> | </>
                <Link onClick={changeGender}>Change</Link>
              </Value>
            </Group>
            <Group>
              <Label>Age range</Label>
              <Value>
                {ageRangeFriendly} <> | </>
                <Link onClick={changeAge}>Change</Link>
              </Value>
            </Group>
            <Group>
              <Label>Education level</Label>
              <Value>
                {educationFriendly}
                <> | </>
                <Link onClick={changeEducationLevel}>Change</Link>
              </Value>
            </Group>
          </DetailsContainer>
          <FormNavigationComponent
            primaryLabel="Back"
            secondaryLabel="Log out"
            primary={back}
            secondary={logOut}
          />
        </Section>
      </Container>
    </Content>
  );
}
