//Higest percentage of answers from a question
export function highestPercentageCalculation(
  response1Count,
  response2Count,
  responseCount
) {
  if (response1Count > response2Count) {
    return Math.round((response1Count / responseCount) * 100);
  } else {
    return Math.round((response2Count / responseCount) * 100);
  }
}

//Popular outlook for a question
export function popularOutlookCalculation(
  response1Count,
  response2Count,
  response1Copy,
  response2Copy
) {
  if (response1Count > response2Count) {
    return response1Copy;
  } else {
    return response2Copy;
  }
}

//User given answer
export function givenAnswer(givenAnswerLabel, response1Copy, response2Copy) {
  if (givenAnswerLabel === "response1Copy") {
    return response1Copy;
  } else {
    return response2Copy;
  }
}

//User given answer %
export function givenAnswerPercentage(
  givenAnswerLabel,
  response1Count,
  response2Count,
  responseCount
) {
  if (givenAnswerLabel === "response1Copy") {
    return Math.round((response1Count / responseCount) * 100);
  } else {
    return Math.round((response2Count / responseCount) * 100);
  }
}

// //Outof10 calculation old
// export function outOf10Calculation(
//   popularOutlook,
//   response1Copy,
//   genderResponseCount,
//   genderResponse1Count,
//   genderResponse2Count
// ) {
//   if (popularOutlook === response1Copy && genderResponseCount !== 0) {
//     return Math.round((genderResponse1Count / genderResponseCount) * 10);
//   } else if (popularOutlook !== response1Copy && genderResponseCount !== 0) {
//     return Math.round((genderResponse2Count / genderResponseCount) * 10);
//   } else {
//     return "No data";
//   }
// }

//Outof10 calculation new
export function outOf10Calculation(
  givenAnswerLabel,
  genderResponseCount,
  genderResponse1Count,
  genderResponse2Count
) {
  if (givenAnswerLabel === "response1Copy" && genderResponseCount !== 0) {
    return Math.round((genderResponse1Count / genderResponseCount) * 10);
  } else if (
    givenAnswerLabel !== "response1Copy" &&
    genderResponseCount !== 0
  ) {
    return Math.round((genderResponse2Count / genderResponseCount) * 10);
  } else {
    return "No data";
  }
}

// //Percentage calculations old
// export function percentageCalculation(
//   popularOutlook,
//   response1Copy,
//   groupResponseCount,
//   groupResponse1Count,
//   groupResponse2Count
// ) {
//   if (popularOutlook === response1Copy && groupResponseCount !== 0) {
//     return Math.round((groupResponse1Count / groupResponseCount) * 100);
//   } else if (popularOutlook !== response1Copy && groupResponseCount !== 0) {
//     return Math.round((groupResponse2Count / groupResponseCount) * 100);
//   } else {
//     return "No data";
//   }
// }

// //Percentage calculations new
export function percentageCalculation(
  givenAnswerLabel,
  groupResponseCount,
  groupResponse1Count,
  groupResponse2Count
) {
  if (givenAnswerLabel === "response1Copy" && groupResponseCount !== 0) {
    return Math.round((groupResponse1Count / groupResponseCount) * 100);
  } else if (givenAnswerLabel !== "response1Copy" && groupResponseCount !== 0) {
    return Math.round((groupResponse2Count / groupResponseCount) * 100);
  } else {
    return "No data";
  }
}
