import logo from "./logo.svg";
import logoWhite from "./logo-white.svg";
import menuIcon from "./menu-icon.svg";
import menuIconWhite from "./menu-icon-white.svg";

import femaleModel from "./femaleModel.svg";
import maleModel from "./maleModel.svg";
import nonBinaryModel from "./nonBinaryModel.svg";
import femaleModelRed from "./femaleModelRed.svg";
import maleModelRed from "./maleModelRed.svg";
import nonBinaryModelRed from "./nonBinaryModelRed.svg";
import closeIcon from "./close-icon.svg";

export var allIcons = {
  logo: logo,
  logoWhite: logoWhite,
  menuIcon: menuIcon,
  menuIconWhite: menuIconWhite,
  femaleModel: femaleModel,
  maleModel: maleModel,
  nonBinaryModel: nonBinaryModel,
  femaleModelRed: femaleModelRed,
  maleModelRed: maleModelRed,
  nonBinaryModelRed: nonBinaryModelRed,
  closeIcon: closeIcon,
};
