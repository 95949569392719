import React from "react";
import styled from "styled-components";
import { SingleBarComponent } from "../atoms/SingleBar";

const Container = styled.div`
  width: 100%;
  margin-bottom: 32px;
`;

const Header = styled.h2`
  margin-top: 0px;
  margin-bottom: 4px;
`;

const Context = styled.h4`
  margin-top: 0px;
`;

export function BarChartGroupComponent(property) {
  return (
    <Container>
      <Header>Breakdown by {property.breakdown}</Header>
      <Context>{property.context}</Context>
      <SingleBarComponent
        yesPercentage={property.yesPercentage1}
        label={property.label1}
      />
      <SingleBarComponent
        yesPercentage={property.yesPercentage2}
        label={property.label2}
      />
      <SingleBarComponent
        yesPercentage={property.yesPercentage3}
        label={property.label3}
      />
      <SingleBarComponent
        yesPercentage={property.yesPercentage4}
        label={property.label4}
      />
    </Container>
  );
}
