import React, { useState } from "react";
import { useHistory } from "react-router-dom";
import styled from "styled-components";
import { FormNotificationComponent } from "../atoms/FormNotification";
import { FormNavigationComponent } from "./FormNavigation";

const ShareWrapper = styled.div`
  box-sizing: border-box;
  -moz-box-sizing: border-box;
  -webkit-box-sizing: border-box;
  border-top-style: solid;
  border-color: var(--light-grey);
  border-width: 1px;
  padding-top: 16px;
  display: flex;
  padding-bottom: 16px;
  flex-direction: row;
  width: 100%;

  @media (max-width: 639px) {
    width: 100%;
    flex-direction: column;
  }
`;

export function ShareCTA(properties) {
  const [isLinkCopied, setIsLinkCopied] = useState(false);
  const [copyHasFailed, setCopyHasFailed] = useState(false);

  function updateClipboard() {
    const newClip = `https://loopsage.com/question/${properties.id}`;
    navigator.clipboard.writeText(newClip).then(
      function () {
        /* clipboard successfully set */
        console.log("Success");
        setIsLinkCopied(true);
        //code before the pause
        setTimeout(function () {
          setIsLinkCopied(false);
        }, 10000);
      },
      function () {
        /* clipboard write failed */
        console.log("Failure");
        setCopyHasFailed(true);
      }
    );
  }

  let history = useHistory();

  function goHome() {
    history.push("/home");
    window.scrollTo(0, 0);
  }

  function content() {
    if (isLinkCopied) {
      return (
        <ShareWrapper>
          <FormNotificationComponent
            type="success"
            header="Link copied!"
            body="Now just paste it in your nearest groupchat to find out what your friends and family think."
          />
        </ShareWrapper>
      );
    } else if (copyHasFailed) {
      return (
        <ShareWrapper>
          <FormNotificationComponent
            type="info"
            header="Your browser didn't let us copy the link"
            body="You can still share the question by manually copying the web address."
          />
        </ShareWrapper>
      );
    } else {
      return (
        <FormNavigationComponent
          primaryLabel="Get shareable link"
          secondaryLabel="Back to all questions"
          secondary={goHome}
          primary={updateClipboard}
        />
      );
    }
  }

  return <>{content()}</>;
}
