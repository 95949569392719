import React from "react";
import { allIcons } from "../xyz-svgs/svg";
import { useHistory, useLocation } from "react-router-dom";
import styled from "styled-components";

const Header = styled.div`
  position: fixed;
  top: 0;
  left: 0;
  background-color: white;
  height: 64px;
  width: 100%;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
  color: white;
  border-bottom-style: solid;
  border-bottom-color: var(--light-grey);
  border-bottom-width: 1px;
  z-index: 9;
`;

const HeaderContainer = styled.div`
  width: 100%;
  max-width: 1024px;
  height: 100%;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: flex-start;
  cursor: pointer;
  padding-left: 16px;
  padding-right: 16px;

  @media (max-width: 768px) {
    padding-right: 0;
  }
`;

const Logo = styled.img`
  margin-top: 8px;
`;

const MenuList = styled.ul`
flex-direction: row;
align-items: center;
justify-content: flex-end;
list-style: none;
overflow: hidden;
height: 100%;

margin-bottom: 0;
margin-top: 0;

@media (min-width: 769px) {
  display: flex;
  }

  @media (max-width: 768px) {
    display: none;


`;

const NavMenu = styled.div`
  height: 100%;
  width: 100%;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: flex-end;
  overflow: hidden;
`;

const MenuIcon = styled.img`
  @media (max-width: 768px) {
    display: block;
  }

  @media (min-width: 769px) {
    display: none;
  }
`;

const MenuItem = styled.li`
  padding: 0;
  margin-left: 32px;
  text-align: center;
  font-family: "Montserrat", sans-serif;
  font-size: 16px;
  text-decoration: none;
  color: var(--cta);

  &&:active {
    color: black;
  }
`;

const Link = styled.a`
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  cursor: pointer;
  text-decoration: none;
  font-family: "Montserrat", sans-serif;
  font-size: 16px;
`;

export function AppHeader(property) {
  let history = useHistory();
  let location = useLocation();

  function goHome() {
    history.push("/home");
    window.scrollTo(0, 0);
  }

  function goToAboutUs() {
    history.push("/about-us");
    window.scrollTo(0, 0);
  }

  function goToLogIn() {
    window.localStorage.setItem("lastLocation", location.pathname);
    history.push("/log-in");
    window.scrollTo(0, 0);
  }

  function goToAccount() {
    window.localStorage.setItem("lastLocation", location.pathname);
    history.push("/account");
    window.scrollTo(0, 0);
  }

  function hasLoggedIn() {
    if (property.hasLoggedIn) {
      return <MenuItem onClick={goToAccount}>Account</MenuItem>;
    } else {
      return <MenuItem onClick={goToLogIn}>Log in</MenuItem>;
    }
  }

  return (
    <Header>
      <HeaderContainer>
        <Link href="/home">
          {" "}
          <Logo src={allIcons.logo} alt="Logo" />
        </Link>

        <NavMenu>
          <MenuIcon
            src={allIcons.menuIcon}
            alt="menu"
            onClick={property.toShowOverlay}
          />
          <MenuList>
            <MenuItem onClick={goHome}>Questions</MenuItem>
            <MenuItem onClick={goToAboutUs}>About us</MenuItem>
            {hasLoggedIn()}
          </MenuList>
        </NavMenu>
      </HeaderContainer>
    </Header>
  );
}
