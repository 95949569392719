import { useEffect, useState } from "react";
import { getQuestionDataById } from "../xyzFirebase/firebaseQueries";

export function QuestionContainer(properties) {
  const [question, setQuestion] = useState();

  useEffect(() => {
    getQuestionDataById(properties.id).then((data) => {
      setQuestion(data);
    });
  }, [properties.id]);

  if (question === undefined) {
    return null;
  }

  return properties.children(question);
}
