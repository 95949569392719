import React from "react";
import { ExtraInformationForm } from "../organisms/ExtraInformationForm";
import { QuestionContainer } from "../organisms/QuestionContainer";

export function ExtraInformationComponent(properties) {
  return (
    <QuestionContainer id={properties.id}>
      {(question) => {
        if (!question) {
          return null;
        }

        return <ExtraInformationForm question={question} />;
      }}
    </QuestionContainer>
  );
}
