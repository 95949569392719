import React from "react";
import { allIcons } from "../xyz-svgs/svg";
import { useHistory, useLocation } from "react-router-dom";
import styled from "styled-components";

const OverLay = styled.div`
  z-index: 10;
  position: fixed;
  align-items: flex-start;
  justify-content: center;
  top: 0;
  left: 0;
  width: 100%;
  height: 100vh;
  overflow: hidden;
  background-color: var(--text-grey);
`;

const OverLayContainer = styled.div`
  display: flex;
  flex-direction: column;
  height: fit-content;
  width: 100%;
`;

const HeaderContainer = styled.div`
  width: 100%;
  max-width: 1024px;
  height: 100%;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: flex-start;
  cursor: pointer;
  padding-left: 16px;
  padding-right: 16px;

  @media (max-width: 768px) {
    padding-right: 0;
`;

const NavMenu = styled.div`
  height: 100%;
  width: 100%;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: flex-end;
  overflow: hidden;
`;

const MenuList = styled.ul`
  text-align: center;
  padding: 0;
  margin-top: 64px;
`;

const MenuItems = styled.li`
text-decoration: none;
list-style-type: none;
margin-bottom 32px;
font-family: "Montserrat", sans-serif;
font-size: 24px;
font: weight 700;
color: white;
`;

const MenuIcon = styled.img``;

const Logo = styled.img`
  margin-top: 8px;
`;

export function MenuOverlay(property) {
  let history = useHistory();
  let location = useLocation();

  function goHome() {
    history.push("/home");
    window.scrollTo(0, 0);
  }

  function goToAboutUs() {
    history.push("/about-us");
    window.scrollTo(0, 0);
  }

  function goToLogIn() {
    window.localStorage.setItem("lastLocation", location.pathname);
    history.push("/log-in");
    window.scrollTo(0, 0);
  }

  function goToAccount() {
    window.localStorage.setItem("lastLocation", location.pathname);
    history.push("/account");
    window.scrollTo(0, 0);
  }

  function hasLoggedIn() {
    if (property.hasLoggedIn) {
      return <MenuItems onClick={goToAccount}>Account</MenuItems>;
    } else {
      return <MenuItems onClick={goToLogIn}>Log in</MenuItems>;
    }
  }

  return (
    <OverLay onClick={property.toHideOverlay}>
      <OverLayContainer>
        <HeaderContainer>
          <Logo src={allIcons.logoWhite} alt="Logo" />
          <NavMenu>
            <MenuIcon src={allIcons.menuIconWhite} alt="menu" />
          </NavMenu>
        </HeaderContainer>

        <MenuList>
          <MenuItems onClick={goHome}>Questions</MenuItems>
          <MenuItems onClick={goToAboutUs}>About us</MenuItems>
          {hasLoggedIn()}
        </MenuList>
      </OverLayContainer>
    </OverLay>
  );
}
