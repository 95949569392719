import React, { useState, useEffect } from "react";
import { SingleQuestionComponent } from "../molecules/SingleQuestion";
import styled from "styled-components";
import { getQuestionIds } from "../xyzFirebase/firebaseQueries";
import { QuestionSummaryContainer } from "./QuestionSummaryContainer";

const Container = styled.div`
  width: 100%;
  display: flex;
  flex-direction: column;
  justify-content: center;

  @media (min-width: 449px) {
    margin-bottom: 16px;
    margin-top: 12px;
  }

  @media (max-width: 448px) {
    margin-bottom: 16px;
    margin-top: 12px;
  }
`;

const SummaryGrid = styled.div`
  display: grid;
  width: 100%;
  max-width: 992px;

  @media (min-width: 1024px) {
    grid-template-columns: repeat(3, minmax(0, 1fr));
    grid-column-gap: 16px;
    grid-row-gap: 16px;
  }

  @media (max-width: 1023px) {
    grid-template-columns: repeat(2, minmax(0, 1fr));
    grid-column-gap: 16px;
    grid-row-gap: 32px;
  }

  @media (max-width: 576px) {
    grid-template-columns: auto;
    grid-column-gap: 0px;
    grid-row-gap: 24px;
  }
`;

export function QuestionListComponent(properties) {
  const [questionsId, setQuestionsId] = useState([]);

  useEffect(() => {
    getQuestionIds().then((data) => {
      setQuestionsId(data.reverse());
    });
  }, []);

  if (!questionsId.length) {
    return null;
  }

  return (
    <Container>
      <SummaryGrid>
        {questionsId.map((id) => {
          return (
            <QuestionSummaryContainer id={id} key={id}>
              {(questionData) => {
                if (questionData) {
                  return (
                    <SingleQuestionComponent question={questionData} id={id} />
                  );
                }
                return null;
              }}
            </QuestionSummaryContainer>
          );
        })}
      </SummaryGrid>
    </Container>
  );
}
