import React from "react";
import styled from "styled-components";

const Content = styled.div`
  width: 100%;
  display: flex;
  justify-content: center;
  padding-bottom: 32px;
`;

const Container = styled.div`
  @media (min-width: 449px) {
    margin-top: 70px;
    margin-bottom: 0px;
    width: 100%;
    height: 100%;
    margin-left: 16px;
    margin-right: 16px;
    max-width: 992px;
    height: fit-content;
    display: flex;
    flex-direction: column;
    justify-content: center;
  }

  @media (max-width: 448px) {
    margin-top: 70px;
    margin-bottom: 0px;
    margin-left: 16px;
    margin-right: 16px;
    width: 100%;
    height: 100%;
    max-width: 992px;
    height: fit-content;
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    justify-content: center;
  }
`;

const TextWidth = styled.div`
  max-width: 656px;
`;

export function ContactUs() {
  return (
    <Content>
      <Container>
        <TextWidth>
          <h1>Contact us</h1>
          <p>
            Do you want to ask us something? Or maybe you have a great poll
            question suggestion? Either way, you can get in contact us with us
            by sending an email to{" "}
            <a href="mailto:hello@loopsage.com">hello@loopsage.com</a>.<br></br>
          </p>

          <p></p>
        </TextWidth>
      </Container>
    </Content>
  );
}
