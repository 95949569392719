import React from "react";
import styled from "styled-components";
import { thousandSeparator } from "../Utilities/maths";

const Container = styled.div``;

const SummaryBox = styled.div`
  border-bottom: solid 1px var(--light-grey);
  display: flex;
  width: 100%;
  padding-top: 16px;
  padding-bottom: 0px;
  margin-bottom: 16px;

  flex-direction: column;
  padding-bottom: 16px;
`;

const Statement = styled.h1`
  border-top: solid 1px var(--light-grey);
  margin-top: 0;
  margin-bottom: 0;
  padding-top: 16px;
`;

const StatSummary = styled.h3`
  margin-top: 0;
`;

export function ResultsHeaderComponent(properties) {
  return (
    <Container>
      <h1>{properties.questionCopy}</h1>
      <SummaryBox>
        <Statement>
          <em>{properties.mainStat}%</em> of{" "}
          {thousandSeparator(properties.responseCount)} people
        </Statement>

        <StatSummary>say {properties.popularOutlook}</StatSummary>
      </SummaryBox>
    </Container>
  );
}
