import React from "react";
import styled from "styled-components";

const Content = styled.div`
  width: 100%;
  display: flex;
  justify-content: center;
  padding-bottom: 32px;
`;

const Container = styled.div`
  @media (min-width: 449px) {
    margin-top: 70px;
    margin-bottom: 0px;
    width: 100%;
    height: 100%;
    margin-left: 16px;
    margin-right: 16px;
    max-width: 992px;
    height: fit-content;
    display: flex;
    flex-direction: column;
    justify-content: center;
  }

  @media (max-width: 448px) {
    margin-top: 70px;
    margin-bottom: 0px;
    margin-left: 16px;
    margin-right: 16px;
    width: 100%;
    height: 100%;
    max-width: 992px;
    height: fit-content;
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    justify-content: center;
  }
`;

const TextWidth = styled.div`
  max-width: 656px;
`;

export function AboutUs() {
  return (
    <Content>
      <Container>
        <TextWidth>
          <h1>About us</h1>
          <p>
            From age old debates like ‘what came first - the chicken or the
            egg?’ to more topical and controversial questions, we’re on a
            mission to find out what the world really thinks.
          </p>
          <p>
            Using thought-provoking questions, loopsage is designed to give
            anonymised data based on your opinions. We then compare the results
            with others from across the country. Whether it’s pop culture and
            politics or facts versus fiction, share your views with us today!
          </p>
          <p>
            By the way, anonymised is just a fancy way of saying that all the
            data will be anonymous and can’t be used to identify anyone. That
            way, you can let us know what you truly think without the worry of
            being judged or feeling like the odd one out.
          </p>
          <p></p>
        </TextWidth>
      </Container>
    </Content>
  );
}
