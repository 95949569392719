import React, { useEffect, useState } from "react";
import { useHistory } from "react-router-dom";
import { ButtonComponent } from "../atoms/Button";
import styled from "styled-components";
import firebase from "firebase/app";
import { getParticipantByUid } from "../xyzFirebase/firebaseQueries";
import { updateData } from "../xyzFirebase/firebaseQueries";

const Container = styled.div`
  margin-top: 16px;
`;

const ImageBox = styled.div`
  width: 100%;
  margin-bottom: 16px;
`;

const Image = styled.img`
  width: 100%;
`;

const Context = styled.h4`
  text-align: center;
  margin-bottom: 16px;
  margin-top: 0px;
`;

const QuestionWrapper = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: center;
  width: 100%;
  margin-bottom: 16px;
  padding-top: 8px;
  padding-bottom: 8px;

  border-style: solid;
  border-color: var(--light-grey);
  border-bottom-width: 1px;
  border-top-width: 1px;
  border-left-width: 0;
  border-right-width: 0;

  @media (min-width: 449px) {
    max-width: 992px;
  }

  @media (max-width: 449px) {
    max-width: 414px;
  }
`;

const Question = styled.h2`
  margin-top: 0;
  margin-bottom: 0;
  text-align: center;
`;

const ButtonWrapper = styled.div`
  display: flex;
  width: 100%;
  justify-content: center;

  @media (min-width: 449px) {
    flex-direction: row;
  }

  @media (max-width: 448px) {
    flex-direction: column;
    max-width: 414px;
  }
`;

const ButtonWrapperLeft = styled.div`
  @media (min-width: 449px) {
    display: flex;
    flex-direction: row;
    justify-content: flex-end;
    width: 100%;
    margin-right: 8px;
  }
`;

const ButtonWrapperRight = styled.div`
  @media (min-width: 449px) {
    display: flex;
    flex-direction: row;
    justify-content: flex-start;
    width: 100%;
    margin-left: 8px;
  }
`;

const ImageShoutOut = styled.p`
  margin-top: 64px;
  margin-bottom: 0;
  text-align: center;
  width: 100%;
`;

export function BinaryQuestionComponent(properties) {
  const [participantId, setParticipantId] = useState(undefined);
  const [participantGender, setParticipantGender] = useState(undefined);
  const [participantAgeGroup, setParticipantAgeGroup] = useState(undefined);
  const [participantEduLevel, setParticipantEduLevel] = useState(undefined);
  const [doesDataExist, setDoesDataExist] = useState(false);
  const [questionAnswered, setQuestionAnswered] = useState(false);

  let history = useHistory();

  useEffect(() => {
    if (firebase.auth().currentUser !== null) {
      getParticipantByUid(firebase.auth().currentUser.uid).then((doc) => {
        if (doc.length !== 0) {
          if (doc[0].id !== undefined) {
            setParticipantId(doc[0].id);
          }
          if (doc[0].data.gender !== undefined) {
            setParticipantGender(doc[0].data.gender);
          }
          if (doc[0].data.ageGroup !== undefined) {
            setParticipantAgeGroup(doc[0].data.ageGroup);
          }
          if (doc[0].data.educationGroup !== undefined) {
            setParticipantEduLevel(doc[0].data.educationGroup);
          }
          if (
            doc[0].data.gender !== undefined &&
            doc[0].data.ageGroup !== undefined &&
            doc[0].data.educationGroup !== undefined
          ) {
            setDoesDataExist(true);
          }
        }
      }, []);
    }
  });

  useEffect(() => {
    if (firebase.auth().currentUser !== null) {
      getParticipantByUid(firebase.auth().currentUser.uid).then((doc) => {
        if (doc.length !== 0) {
          if (doc[0].data[properties.id] !== undefined) {
            console.log("question answered");
            setQuestionAnswered(true);
          }
        }
      });
    }
  }, [properties.id]);

  async function nextStep(id, response) {
    if (firebase.auth().currentUser === null) {
      window.localStorage.setItem("lastQuestion", properties.id);
      window.localStorage.setItem(
        "QuestionCopy",
        properties.question.questionCopy
      );
      history.push(`/mid-sign-up`, { answer: response });

      window.scrollTo(0, 0);
    } else if (doesDataExist && questionAnswered === false) {
      // Auto complete question code

      //  participantId,
      //  ageGroup,
      //  educationLevel,
      //  gender,
      //  questionid,
      //  doesDataExist,
      //  response

      updateData(
        participantId,
        participantAgeGroup,
        participantEduLevel,
        participantGender,
        id,
        doesDataExist,
        response
      ).then(() => {
        history.push(`/results/${id}`);
        window.scrollTo(0, 0);
      });
    } else if (
      firebase.auth().currentUser !== null &&
      doesDataExist === false &&
      questionAnswered === false
    ) {
      history.push(`/extra-info/${id}`, { answer: response });
      window.scrollTo(0, 0);
    } else if (questionAnswered) {
      history.push(`/extra-info/${id}`, { answer: response });
      window.scrollTo(0, 0);
    }
  }

  if (!properties.question) {
    return null;
  }

  function outlook1(id) {
    nextStep(id, 1);
  }

  function outlook2(id) {
    nextStep(id, 2);
  }

  function capitaliseFirstLetter(copy) {
    return copy.charAt(0).toUpperCase() + copy.slice(1);
  }

  return (
    <Container>
      <ImageBox>
        <Image
          src={properties.question.imageRef}
          alt={properties.question.questionCopy}
        />
      </ImageBox>
      <Context>What's your view?</Context>
      <QuestionWrapper>
        <Question>{properties.question.questionCopy}</Question>
      </QuestionWrapper>
      <ButtonWrapper>
        <ButtonWrapperLeft>
          <ButtonComponent
            text={capitaliseFirstLetter(properties.question.response1Copy)}
            onClick={() => {
              outlook1(properties.id);
            }}
          />
        </ButtonWrapperLeft>
        <ButtonWrapperRight>
          <ButtonComponent
            text={capitaliseFirstLetter(properties.question.response2Copy)}
            onClick={() => {
              outlook2(properties.id);
            }}
          />
        </ButtonWrapperRight>
      </ButtonWrapper>
      <ImageShoutOut> Image by {properties.question.shoutOut} </ImageShoutOut>
    </Container>
  );
}
