import React from "react";
import styled from "styled-components";
import { allIcons } from "../xyz-svgs/svg";

const Container = styled.div`
  display: flex;
  justify-content: space-between;
  height: auto;
`;

const GenderGrid = styled.div`
  position: relative;
  width: 100%;
`;

const SingleModel = styled.img`
  width: 9%;
  height: 100%;
`;

export function GenderGridComponent(property) {
  function checkData() {
    if (property.of10Num !== "No data") {
      return property.of10Num;
    } else {
      return 0;
    }
  }

  var allModels = [];

  var i;

  for (i = 0; i < checkData(); i++) {
    allModels.push(<GenderModelComponentRed key={`genderModel-${i}`} />);
  }

  for (i = checkData(); i < 10; i++) {
    allModels.push(<GenderModelComponent key={`genderModel-${i}`} />);
  }

  function whichModel() {
    if (property.gender === "women") {
      return allIcons.femaleModel;
    } else if (property.gender === "men") {
      return allIcons.maleModel;
    } else if (property.gender === "non-binaries") {
      return allIcons.nonBinaryModel;
    }
  }

  function whichModelRed() {
    if (property.gender === "women") {
      return allIcons.femaleModelRed;
    } else if (property.gender === "men") {
      return allIcons.maleModelRed;
    } else if (property.gender === "non-binaries") {
      return allIcons.nonBinaryModelRed;
    }
  }
  function GenderModelComponent() {
    return <SingleModel src={whichModel()} />;
  }

  function GenderModelComponentRed() {
    return <SingleModel src={whichModelRed()} />;
  }

  return (
    <GenderGrid>
      <Container>{allModels}</Container>
    </GenderGrid>
  );
}
