import React from "react";
import styled from "styled-components";
import ".././App.css";

const ErrorContainer = styled.div`
  width: 100%;
  padding: 16px;
  display: flex;
  flex-direction: column;
  margin-top: 14px;

  box-sizing: border-box;
  -moz-box-sizing: border-box;
  -webkit-box-sizing: border-box;
  border-style: solid;
  border-width: 1px;
  ${notificationType}
`;

const Header = styled.h4`
  margin-bottom: 4px;
  margin-top: 0;
`;

const Body = styled.p`
  margin: 0;
  color: black;
`;

function notificationType(property) {
  if (property.type === "error") {
    return "border-color: var(--primary-color); background-color: #edc3b9;";
  } else if (property.type === "success") {
    return "border-color: #2ECC71; background-color: #C0F0D4;";
  } else if (property.type === "info") {
    return "border-color: #2980B9; background-color: #BFD9EA;";
  }
}

export function FormNotificationComponent(property) {
  return (
    <ErrorContainer type={property.type}>
      <Header>{property.header}</Header>
      <Body>{property.body}</Body>
    </ErrorContainer>
  );
}
