import React, { useState, useEffect } from "react";
import { GenderGridGroupComponent } from "../molecules/GenderGridGroup";
import { ResultsHeaderComponent } from "../molecules/ResultsHeader";
import { BarChartGroupComponent } from "../molecules/BarChartGroup";
import { ShareCTA } from "../molecules/ShareCTA";
// import { highestPercentageCalculation } from "../calculations";
// import { popularOutlookCalculation } from "../calculations";
import { givenAnswer } from "../calculations";
import { givenAnswerPercentage } from "../calculations";
import { outOf10Calculation } from "../calculations";
import { percentageCalculation } from "../calculations";
import styled from "styled-components";

const Content = styled.div`
  width: 100%;
  display: flex;
  justify-content: center;
  padding-bottom: 32px;
`;

const Container = styled.div`
  @media (min-width: 449px) {
    margin-top: 70px;
    margin-bottom: 0px;
    width: 100%;
    height: 100%;
    margin-left: 16px;
    margin-right: 16px;
    max-width: 992px;
    height: fit-content;
    display: flex;
    flex-direction: column;
    justify-content: center;
  }

  @media (max-width: 448px) {
    margin-top: 70px;
    margin-bottom: 0px;
    margin-left: 16px;
    margin-right: 16px;
    width: 100%;
    height: 100%;
    max-width: 992px;
    height: fit-content;
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    justify-content: center;
  }
`;

const ContainerRow = styled.div`
  @media (min-width: 641px) {
    margin-bottom: 0px;
    width: 100%;
    height: 100%;
    max-width: 992px;
    height: fit-content;
    display: flex;
    flex-direction: row;
    align-items: flex-start;
    justify-content: center;
  }

  @media (max-width: 640px) {
    margin-bottom: 0px;
    width: 100%;
    height: 100%;
    max-width: 992px;
    height: fit-content;
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    justify-content: center;
  }
`;

const Section = styled.div`
  @media (min-width: 449px) {
    width: 100%;
    display: flex;
    flex-direction: column;
    justify-content: center;
    margin-bottom: 0;
  }

  @media (max-width: 448px) {
    width: 100%;
    display: flex;
    flex-direction: column;
    justify-content: center;
    margin-bottom: 0;
  }
`;

const BarChartColumn = styled.div`
  @media (min-width: 641px) {
    width: 100%;
    height: 100%;
    margin-left: 32px;
  }

  width: 100%;
  height: 100%;
`;

const GridColumn = styled.div`
  @media (min-width: 769px) {
    display: flex;
    flex-direction: column;
    width: 100%;
    max-width: 404px;
  }
`;

const Line = styled.div`

    display: flex;
    width: 100%
    height:1px;  
    border-top: solid;
    border-width: 1px;
    border-color: var(--light-grey);
    margin-top: 32px;
    margin-bottom: 32px;
    
`;

export function ResultsContent(properties) {
  // const [highestPercentage, setHighestPercentage] = useState();
  // const [popularOutlook, setPopularOutlook] = useState();
  const [outOf10Women, setOutOf10Women] = useState();
  const [givenAnswerCopy, setGivenAnswerCopy] = useState();
  const [givenAnswerPercentile, setGivenAnswerPercentile] = useState();
  const [outOf10Men, setOutOf10Men] = useState();
  const [outOf10NonBinary, setOutOf10NonBinary] = useState();
  const [ageGroup1Percentage, setAgeGroup1Percentage] = useState();
  const [ageGroup2Percentage, setAgeGroup2Percentage] = useState();
  const [ageGroup3Percentage, setAgeGroup3Percentage] = useState();
  const [ageGroup4Percentage, setAgeGroup4Percentage] = useState();
  const [eduLevelDPercentage, setEduLevelDPercentage] = useState();
  const [eduLevelCPercentage, setEduLevelCPercentage] = useState();
  const [eduLevelBPercentage, setEduLevelBPercentage] = useState();
  const [eduLevelAPercentage, setEduLevelAPercentage] = useState();

  // useEffect(() => {
  //   setHighestPercentage(
  //     highestPercentageCalculation(
  //       properties.question.response1Count,
  //       properties.question.response2Count,
  //       properties.question.responseCount
  //     )
  //   );
  // }, [
  //   properties.question.response1Count,
  //   properties.question.response2Count,
  //   properties.question.responseCount,
  // ]);

  // useEffect(() => {
  //   setPopularOutlook(
  //     popularOutlookCalculation(
  //       properties.question.response1Count,
  //       properties.question.response2Count,
  //       properties.question.response1Copy,
  //       properties.question.response2Copy
  //     )
  //   );
  // }, [
  //   properties.question.response1Copy,
  //   properties.question.response1Count,
  //   properties.question.response2Copy,
  //   properties.question.response2Count,
  // ]);

  useEffect(() => {
    setGivenAnswerCopy(
      givenAnswer(
        properties.givenAnswerLabel,
        properties.question.response1Copy,
        properties.question.response2Copy
      )
    );
  }, [
    properties.givenAnswerLabel,
    properties.question.response1Copy,
    properties.question.response2Copy,
  ]);

  useEffect(() => {
    setGivenAnswerPercentile(
      givenAnswerPercentage(
        properties.givenAnswerLabel,
        properties.question.response1Count,
        properties.question.response2Count,
        properties.question.responseCount
      )
    );
  }, [
    properties.givenAnswerLabel,
    properties.question.response1Count,
    properties.question.response2Count,
    properties.question.responseCount,
  ]);

  useEffect(() => {
    setOutOf10Women(
      outOf10Calculation(
        properties.givenAnswerLabel,
        properties.question.femaleResponseCount,
        properties.question.femaleResponse1Count,
        properties.question.femaleResponse2Count
      )
    );
    setOutOf10Men(
      outOf10Calculation(
        properties.givenAnswerLabel,
        properties.question.maleResponseCount,
        properties.question.maleResponse1Count,
        properties.question.maleResponse2Count
      )
    );
    setOutOf10NonBinary(
      outOf10Calculation(
        properties.givenAnswerLabel,
        properties.question.nonBinaryResponseCount,
        properties.question.nonBinaryResponse1Count,
        properties.question.nonBinaryResponse2Count
      )
    );
  }, [
    properties.givenAnswerLabel,
    properties.question.femaleResponseCount,
    properties.question.femaleResponse1Count,
    properties.question.femaleResponse2Count,
    properties.question.maleResponseCount,
    properties.question.maleResponse1Count,
    properties.question.maleResponse2Count,
    properties.question.nonBinaryResponseCount,
    properties.question.nonBinaryResponse1Count,
    properties.question.nonBinaryResponse2Count,
  ]);

  useEffect(() => {
    //calculations for age group
    setAgeGroup1Percentage(
      percentageCalculation(
        properties.givenAnswerLabel,
        properties.question.ageGroup1ResponseCount,
        properties.question.ageGroup1Response1Count,
        properties.question.ageGroup1Response2Count
      )
    );
    setAgeGroup2Percentage(
      percentageCalculation(
        properties.givenAnswerLabel,
        properties.question.ageGroup2ResponseCount,
        properties.question.ageGroup2Response1Count,
        properties.question.ageGroup2Response2Count
      )
    );

    setAgeGroup3Percentage(
      percentageCalculation(
        properties.givenAnswerLabel,
        properties.question.ageGroup3ResponseCount,
        properties.question.ageGroup3Response1Count,
        properties.question.ageGroup3Response2Count
      )
    );
    setAgeGroup4Percentage(
      percentageCalculation(
        properties.givenAnswerLabel,
        properties.question.ageGroup4ResponseCount,
        properties.question.ageGroup4Response1Count,
        properties.question.ageGroup4Response2Count
      )
    );
    //calculations for education level
    setEduLevelDPercentage(
      percentageCalculation(
        properties.givenAnswerLabel,
        properties.question.eduLevelDResponseCount,
        properties.question.eduLevelDResponse1Count,
        properties.question.eduLevelDResponse2Count
      )
    );
    setEduLevelCPercentage(
      percentageCalculation(
        properties.givenAnswerLabel,
        properties.question.eduLevelCResponseCount,
        properties.question.eduLevelCResponse1Count,
        properties.question.eduLevelCResponse2Count
      )
    );
    setEduLevelBPercentage(
      percentageCalculation(
        properties.givenAnswerLabel,
        properties.question.eduLevelBResponseCount,
        properties.question.eduLevelBResponse1Count,
        properties.question.eduLevelBResponse2Count
      )
    );
    setEduLevelAPercentage(
      percentageCalculation(
        properties.givenAnswerLabel,
        properties.question.eduLevelAResponseCount,
        properties.question.eduLevelAResponse1Count,
        properties.question.eduLevelAResponse2Count
      )
    );
  }, [
    properties.givenAnswerLabel,
    properties.question.ageGroup1ResponseCount,
    properties.question.ageGroup1Response1Count,
    properties.question.ageGroup1Response2Count,
    properties.question.ageGroup2ResponseCount,
    properties.question.ageGroup2Response1Count,
    properties.question.ageGroup2Response2Count,
    properties.question.ageGroup3ResponseCount,
    properties.question.ageGroup3Response1Count,
    properties.question.ageGroup3Response2Count,
    properties.question.ageGroup4ResponseCount,
    properties.question.ageGroup4Response1Count,
    properties.question.ageGroup4Response2Count,
    properties.question.eduLevelDResponseCount,
    properties.question.eduLevelDResponse1Count,
    properties.question.eduLevelDResponse2Count,
    properties.question.eduLevelCResponseCount,
    properties.question.eduLevelCResponse1Count,
    properties.question.eduLevelCResponse2Count,
    properties.question.eduLevelBResponseCount,
    properties.question.eduLevelBResponse1Count,
    properties.question.eduLevelBResponse2Count,
    properties.question.eduLevelAResponseCount,
    properties.question.eduLevelAResponse1Count,
    properties.question.eduLevelAResponse2Count,
  ]);

  if (!properties.question) {
    return null;
  }

  return (
    <Content>
      <Container>
        <ResultsHeaderComponent
          questionCopy={properties.question.questionCopy}
          mainStat={givenAnswerPercentile}
          responseCount={properties.question.responseCount}
          popularOutlook={
            givenAnswerCopy + " " + properties.question.questionContext
          }
        />

        <ContainerRow>
          <Section>
            <GridColumn>
              <GenderGridGroupComponent
                of10Num={outOf10Women}
                gender="women"
                context={
                  givenAnswerCopy + " " + properties.question.questionContext
                }
              />
              <Line />
              <GenderGridGroupComponent
                of10Num={outOf10Men}
                gender="men"
                context={
                  givenAnswerCopy + " " + properties.question.questionContext
                }
              />
              <Line />
              <GenderGridGroupComponent
                of10Num={outOf10NonBinary}
                gender="non-binaries"
                context={
                  givenAnswerCopy + " " + properties.question.questionContext
                }
              />
              <Line />
            </GridColumn>
          </Section>

          <BarChartColumn>
            <BarChartGroupComponent
              breakdown="age groups"
              context={
                "Those who say " +
                givenAnswerCopy +
                " " +
                properties.question.questionContext
              }
              label1="18 - 25 years old"
              label2="26 - 30 years old"
              label3="31 - 39 years old"
              label4="40+ years old"
              yesPercentage1={ageGroup1Percentage}
              yesPercentage2={ageGroup2Percentage}
              yesPercentage3={ageGroup3Percentage}
              yesPercentage4={ageGroup4Percentage}
            />
            <Line />
            <BarChartGroupComponent
              breakdown="highest education level"
              context={
                "Those who say " +
                givenAnswerCopy +
                " " +
                properties.question.questionContext
              }
              label1="Secondary school"
              label2="Sixth form / college"
              label3="Bachelor degree"
              label4="Masters / PHD"
              yesPercentage1={eduLevelDPercentage}
              yesPercentage2={eduLevelCPercentage}
              yesPercentage3={eduLevelBPercentage}
              yesPercentage4={eduLevelAPercentage}
            />
          </BarChartColumn>
        </ContainerRow>
        <ShareCTA id={properties.id} />
      </Container>
    </Content>
  );
}
