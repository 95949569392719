import React from "react";
import styled from "styled-components";

const Footer = styled.footer`
  background-color: white;
  height: 100%;
  width: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  color: white;
  padding-top: 16px;
  padding-bottom: 48px;
  border-top-style: solid;
  border-color: var(--light-grey);
  border-width: 1px;
`;

const Link = styled.a`
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  cursor: pointer;
  text-decoration: none;
  font-family: "Montserrat", sans-serif;
  font-size: 16px;
  margin-left: 8px;
  margin-right: 8px;
`;

const LinkContainer = styled.div`
  position: relative;
  display: flex;
  flex-direction: row;
  margin-top: 16px;
`;

export function AppFooter() {
  return (
    <Footer>
      <LinkContainer>
        <Link href="/privacy-policy"> Our privacy policy</Link>
        <Link href="/contact-us"> Contact us</Link>
      </LinkContainer>
      <p>© 2021 loopsage all rights reserved</p>
    </Footer>
  );
}
