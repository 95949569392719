import React from "react";
import styled from "styled-components";

const Content = styled.div`
  width: 100%;
  display: flex;
  justify-content: center;
  padding-bottom: 32px;
`;

const Container = styled.div`
  @media (min-width: 449px) {
    margin-top: 70px;
    margin-bottom: 0px;
    width: 100%;
    height: 100%;
    margin-left: 16px;
    margin-right: 16px;
    max-width: 992px;
    height: fit-content;
    display: flex;
    flex-direction: column;
    justify-content: center;
  }

  @media (max-width: 448px) {
    margin-top: 70px;
    margin-bottom: 0px;
    margin-left: 16px;
    margin-right: 16px;
    width: 100%;
    height: 100%;
    max-width: 992px;
    height: fit-content;
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    justify-content: center;
  }
`;

const TextWidth = styled.div`
  max-width: 656px;
`;

export function PrivacyPolicy() {
  return (
    <Content>
      <Container>
        <TextWidth>
          <h1>Our Privacy Policy</h1>

          <p>
            At loopsage, accessible from loopsage.com, one of our main
            priorities is the privacy of our visitors. This Privacy Policy
            document contains types of information that is collected and
            recorded by loopsage and how we use it.
          </p>

          <p>
            If you have additional questions or require more information about
            our Privacy Policy, do not hesitate to contact us.
          </p>

          <p>
            This Privacy Policy applies only to our online activities and is
            valid for visitors to our website with regards to the information
            that they shared and/or collect in loopsage. This policy is not
            applicable to any information collected offline or via channels
            other than this website.
          </p>

          <h2>Consent</h2>

          <p>
            By using our website, you hereby consent to our Privacy Policy and
            agree to its terms.
          </p>

          <h2>Information we collect</h2>

          <p>
            The personal information that you are asked to provide, and the
            reasons why you are asked to provide it, will be made clear to you
            at the point we ask you to provide your personal information.
          </p>
          <p>
            If you contact us directly, we may receive additional information
            about you such as your name, email address, phone number, the
            contents of the message and/or attachments you may send us, and any
            other information you may choose to provide.
          </p>

          <h2>How we use your information</h2>

          <p>
            We use the information we collect in various ways, including to:
          </p>

          <ul>
            <li>Provide, operate, and maintain our webste</li>
            <li>Improve and expand our webste</li>
            <li>Develop new services, features, and functionality</li>
          </ul>

          <h2>Log Files</h2>

          <p>
            loopsage follows a standard procedure of using log files. These
            files log visitors when they visit websites. All hosting companies
            do this and a part of hosting services' analytics. The information
            collected by log files include internet protocol (IP) addresses,
            browser type, Internet Service Provider (ISP), date and time stamp,
            referring/exit pages, and possibly the number of clicks. These are
            not linked to any information that is personally identifiable. The
            purpose of the information is for analysing trends, administering
            the site, tracking users' movement on the website, and gathering
            demographic information.
          </p>

          <h2>Cookies and Web Beacons</h2>

          <p>
            Like any other website, loopsage uses 'cookies'. However, loopsage
            does not use cookies to track or monitor our users. Instead, cookies
            are used to maintain website functionality such as remebering when
            users are logged in.
          </p>

          <p>
            For more general information on cookies, please read{" "}
            <a href="https://www.cookieconsent.com/what-are-cookies/">
              "What Are Cookies" from Cookie Consent
            </a>
            .
          </p>

          <h2>GDPR Data Protection Rights</h2>

          <p>
            We would like to make sure you are fully aware of all of your data
            protection rights. Every user is entitled to the following:
          </p>
          <p>
            The right to access – You have the right to request copies of your
            personal data. We may charge you a small fee for this service.
          </p>
          <p>
            The right to rectification – You have the right to request that we
            correct any information you believe is inaccurate. You also have the
            right to request that we complete the information you believe is
            incomplete.
          </p>
          <p>
            The right to erasure – You have the right to request that we erase
            your personal data, under certain conditions.
          </p>
          <p>
            The right to restrict processing – You have the right to request
            that we restrict the processing of your personal data, under certain
            conditions.
          </p>
          <p>
            The right to object to processing – You have the right to object to
            our processing of your personal data, under certain conditions.
          </p>
          <p>
            The right to data portability – You have the right to request that
            we transfer the data that we have collected to another organization,
            or directly to you, under certain conditions.
          </p>
          <p>
            If you make a request, we have one month to respond to you. If you
            would like to exercise any of these rights, please contact us.
          </p>

          <h2>Children's Information</h2>

          <p>
            Another part of our priority is adding protection for children while
            using the internet. We encourage parents and guardians to observe,
            participate in, and/or monitor and guide their online activity.
          </p>

          <p>
            loopsage does not knowingly collect any Personal Identifiable
            Information from children under the age of 16. If you think that
            your child provided this kind of information on our website, we
            strongly encourage you to contact us immediately and we will do our
            best efforts to promptly remove such information from our records.
          </p>
        </TextWidth>
      </Container>
    </Content>
  );
}
