import React from "react";
import styled from "styled-components";
import ".././App.css";
import { Loader } from "../atoms/Loader";

//Styled component named Button
const StyledButton = styled.button`
  display: flex;
  align-items: center;
  justify-content: center;
  box-sizing: border-box;
  -moz-box-sizing: border-box;
  -webkit-box-sizing: border-box;
  border-style: solid;
  border-width: 1px;
  border-color: var(--cta);
  background-color: var(--cta);
  color: white;
  font-family: "Montserrat", sans-serif;
  font-weight: 700;
  text-align: center;
  height: 48px;
  width: 100%;
  margin-bottom: 16px;
  cursor: pointer;
  @media (max-width: 448px) {
    max-width: 414px;
    font-size: 14px;
  }
  @media (min-width: 449px) {
    max-width: 320px;
    font-size: 16px;
  }

  ${addcssByLevel}
`;

function addcssByLevel(property) {
  if (property.level === "primary") {
    return "background-color: var(--cta); color: white;";
  } else if (property.level === "secondary") {
    return "background-color: white; color: var(--cta);";
  }
}

export function ButtonComponent(property) {
  function buttonContent() {
    if (property.loading === true) {
      return <Loader />;
    } else return <>{property.text}</>;
  }

  return (
    <StyledButton onClick={property.onClick} level={property.level}>
      {buttonContent()}
    </StyledButton>
  );
}
