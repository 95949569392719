import React, { useState } from "react";
// import { FormNotificationComponent } from "../atoms/FormNotification";
import { TextInputComponent } from "../atoms/TextInput";
import { FormNavigationComponent } from "../molecules/FormNavigation";
import styled from "styled-components";
import { db } from "../xyzFirebase/firebase";
import firebase from "firebase/app";

const Content = styled.div`
  width: 100%;
  display: flex;
  justify-content: center;
  padding-bottom: 32px;
`;

const Container = styled.div`
  @media (min-width: 449px) {
    margin-top: 70px;
    margin-bottom: 0px;
    width: 100%;
    height: 100%;
    margin-left: 16px;
    margin-right: 16px;
    max-width: 992px;
    height: fit-content;
    display: flex;
    flex-direction: column;
    justify-content: center;
  }

  @media (max-width: 448px) {
    margin-top: 70px;
    margin-bottom: 0px;
    margin-left: 16px;
    margin-right: 16px;
    width: 100%;
    height: 100%;
    max-width: 992px;
    height: fit-content;
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    justify-content: center;
  }
`;

export function NewQuestionForm() {
  const [questionCopy, setQuestionCopy] = useState();
  const [response1Copy, setResponse1Copy] = useState();
  const [response2Copy, setResponse2Copy] = useState();
  const [context, setContext] = useState("");
  const [imageRef, setImageRef] = useState("");
  const [shoutOut, setShoutOut] = useState("");
  // const [missingDetailsError, setMissingDetailserror] = useState(false);

  // function onFormsubmission() {
  //   if (missingDetailsError === true) {
  //     return <FormNotificationComponent />;
  //   } else {
  //     return null;
  //   }
  // }

  function addNewQuestion() {
    db.collection("questions-basic")
      .add({
        questionCopy: questionCopy,
        questionContext: context,
        response1Copy: response1Copy,
        response2Copy: response2Copy,
        shoutOut: shoutOut,
        imageRef: imageRef,
        TimeStamp: firebase.firestore.Timestamp.now(),
        responseCount: 0,
        response1Count: 0,
        response2Count: 0,
        maleResponseCount: 0,
        maleResponse1Count: 0,
        maleResponse2Count: 0,
        femaleResponseCount: 0,
        femaleResponse1Count: 0,
        femaleResponse2Count: 0,
        nonBinaryResponseCount: 0,
        nonBinaryResponse1Count: 0,
        nonBinaryResponse2Count: 0,
        ageGroup1ResponseCount: 0,
        ageGroup1Response1Count: 0,
        ageGroup1Response2Count: 0,
        ageGroup2ResponseCount: 0,
        ageGroup2Response1Count: 0,
        ageGroup2Response2Count: 0,
        ageGroup3ResponseCount: 0,
        ageGroup3Response1Count: 0,
        ageGroup3Response2Count: 0,
        ageGroup4ResponseCount: 0,
        ageGroup4Response1Count: 0,
        ageGroup4Response2Count: 0,
        eduLevelAResponseCount: 0,
        eduLevelAResponse1Count: 0,
        eduLevelAResponse2Count: 0,
        eduLevelBResponseCount: 0,
        eduLevelBResponse1Count: 0,
        eduLevelBResponse2Count: 0,
        eduLevelCResponseCount: 0,
        eduLevelCResponse1Count: 0,
        eduLevelCResponse2Count: 0,
        eduLevelDResponseCount: 0,
        eduLevelDResponse1Count: 0,
        eduLevelDResponse2Count: 0,
      })
      .then(function (docRef) {
        // console.log("Document written with ID: ", docRef.id);
        addNewQuestionSummary(docRef.id);
      })
      .catch(function (error) {
        console.error("Error writing document: ", error);
      });
  }

  function addNewQuestionSummary(id) {
    const questionRef = db.collection("homeScreen").doc("first100");

    questionRef
      .update({
        [id]: {
          questionCopy: questionCopy,
          questionContext: context,
          response1Copy: response1Copy,
          response2Copy: response2Copy,
          responseCount: 0,
          response1Count: 0,
          response2Count: 0,
          shoutOut: shoutOut,
          imageRef: imageRef,
        },
      })
      .then(function (docRef) {
        console.log("question written");
      })
      .catch(function (error) {
        console.error("Error writing document: ", error);
      });
  }

  return (
    <Content>
      <Container>
        {/* {onFormsubmission()} */}
        <h1>Add a new question</h1>
        <h3>This is a subheader</h3>
        <TextInputComponent
          label="Question copy"
          name="questionCopy"
          onBlur={(event) => setQuestionCopy(event.target.value)}
        />
        <TextInputComponent
          label="response 1 Copy"
          name="response1Copy"
          onBlur={(event) => setResponse1Copy(event.target.value)}
        />
        <TextInputComponent
          label="response 2 Copy"
          name="response2Copy"
          onBlur={(event) => setResponse2Copy(event.target.value)}
        />
        <TextInputComponent
          label="Context"
          name="context"
          onBlur={(event) => setContext(event.target.value)}
        />

        <TextInputComponent
          label="Image ref"
          name="imageRef"
          onBlur={(event) => setImageRef(event.target.value)}
        />

        <TextInputComponent
          label="Image by"
          name="imageBy"
          onBlur={(event) => setShoutOut(event.target.value)}
        />
        <br />
        <FormNavigationComponent
          primaryLabel="Add question"
          secondaryLabel="Clear form"
          primary={addNewQuestion}
        />
      </Container>
    </Content>
  );
}
