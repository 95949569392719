import React from "react";
import styled from "styled-components";
import ".././App.css";

//Radio button container
const RadioContainer = styled.label`
  width: 100%;
  height: 48px;
  display: flex;
  justify-items: flex-end;
  align-items: center;
  position: relative;
  padding: 0;
  margin-bottom: 16px;
  box-sizing: border-box;
  -moz-box-sizing: border-box;
  -webkit-box-sizing: border-box;
  border-style: solid;
  border-width: 1px;
  border-color: var(--grey);

  &:hover {
    background-color: var(--light-grey);
  }
`;

//Radio button label
const RadioLabel = styled.span`
  margin-left: 16px;
`;

//Radio button checker
const RadioChecker = styled.div`
  width: 28px;
  height: 28px;
  display: flex;
  align-items: center;
  justify-content: center;
  position: absolute;
  right: 0;
  margin-right: 16px;
`;

//Inner radio checker
const InnerChecker = styled.div`
  opacity: 100%;
  width: 20px;
  height: 20px;
  display: flex;
  align-items: center;
  justify-content: center;
  box-sizing: border-box;
  -moz-box-sizing: border-box;
  -webkit-box-sizing: border-box;
  border-style: solid;
  border-radius: 100%;
  border-width: 1px;
  border-color: var(--cta);
`;

//Radio checker dot
const CheckerDot = styled.div`
  opacity: 0%;
  border-radius: 100%;
  width: 12px;
  height: 12px;
  background-color: var(--cta);
`;

//Radio button input
const ActualInput = styled.input`
  position: absolute;
  opacity: 0;
  height: 0;
  width: 0;
  appearance: none;
  display: none;

  &:checked + ${RadioContainer} {
    border-color: var(--cta);
    background-color: var(--light-grey);
  }

  &:checked + ${RadioContainer} ${RadioChecker} {
    box-sizing: border-box;
    -moz-box-sizing: border-box;
    -webkit-box-sizing: border-box;
    border-style: solid;
    border-radius: 100%;
    border-width: 1px;
    border-color: var(--cta);
  }

  &:checked + ${RadioContainer} ${InnerChecker} {
    background-color: var(--opaque);
  }

  &:checked + ${RadioContainer} ${CheckerDot} {
    opacity: 100%;
  }

  opacity: 100%;
`;

//Wrapper
const RadioWrapper = styled.div`
  width: auto;
  height: auto;
`;

export function RadioButtonComponent(property) {
  return (
    <RadioWrapper>
      <ActualInput
        id={property.id}
        type="radio"
        name={property.group}
        value={property.value}
        checked={property.checked}
        onChange={property.onChange}
        onBlur={property.onBlur}
      />
      <RadioContainer htmlFor={property.id}>
        <RadioLabel>{property.label}</RadioLabel>
        <RadioChecker>
          <InnerChecker>
            <CheckerDot />
          </InnerChecker>
        </RadioChecker>
      </RadioContainer>
    </RadioWrapper>
  );
}
