import React from "react";
import styled from "styled-components";
import { GenderGridComponent } from "../atoms/GenderGrid";

const Container = styled.div`
  margin-bottom: 16px;
`;

const Stat = styled.h2`
  margin-top: 0px;
  margin-bottom: 4px;
`;

const Context = styled.h4`
  margin-top: 0px;
`;

export function GenderGridGroupComponent(property) {
  function displayText() {
    if (property.of10Num === "No data" || property.of10Num < 0) {
      return (
        <>
          <em>Not enough data</em> available for {property.gender}
        </>
      );
    } else {
      return (
        <>
          <em>{property.of10Num} of 10</em> {property.gender}
        </>
      );
    }
  }

  function displayContext() {
    if (property.of10Num !== "No data" && property.of10Num >= 0) {
      return <>say {property.context}</>;
    } else {
      return <></>;
    }
  }

  return (
    <Container>
      <Stat>{displayText()}</Stat>
      <Context>{displayContext()}</Context>
      <GenderGridComponent
        of10Num={property.of10Num}
        gender={property.gender}
      />
    </Container>
  );
}
