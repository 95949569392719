import React, { useState, useEffect } from "react";
import { allIcons } from "../xyz-svgs/svg";
import styled from "styled-components";
import { ProfileBuilder } from "../organisms/ProfileBuilder";
import { FormNavigationComponent } from "../molecules/FormNavigation";
import { getParticipantByUid } from "../xyzFirebase/firebaseQueries";
import { db } from "../xyzFirebase/firebase";
import firebase from "firebase/app";

const OverLay = styled.div`
  position: fixed;
  z-index: 7;
  align-items: flex-start;
  justify-content: center;
  top: 0;
  left: 0;
  width: 100%;
  height: 100vh;
  overflow: hidden;
  background-color: white;
  margin-top: 64px;
`;

const OverLayContainer = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  height: fit-content;
  width: 100%;
`;

const HeaderContainer = styled.div`
  width: 100%;
  max-width: 1024px;
  height: 64px;
  display: flex;
  align-items: center;
  justify-content: center;
  cursor: pointer;
  padding-right: 16px;
  padding-left: 16px;
`;

const NavMenu = styled.div`
  height: 100%;
  width: 100%;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: flex-end;
  overflow: hidden;
`;

const MenuIcon = styled.img`
  width: 21px;
  height: 21px;
  @media (max-width: 768px) {
    margin-right: 6px;
  }
`;

const FormContainer = styled.div`
  width: 100%;
  max-width: 992px;
  display: flex;
  justify-content: flex-start;
`;

const ContentWrapper = styled.div`
  width: 100%;
  max-width: 1024px;
  padding-right: 16px;
  padding-left: 16px;
`;

export function ChangeOverlay(property) {
  const [gender, setGender] = useState();
  const [ageGroup, setAgeGroup] = useState();
  const [educationLevel, setEducationLevel] = useState();

  const [showGender, setshowGender] = useState();
  const [showAgeGroup, setshowAgeGroup] = useState();
  const [showEducationLevel, setshowEducationLevel] = useState();
  const [pid, setPid] = useState();
  const [doesDocExist, setDoesDocExist] = useState();

  useEffect(() => {
    if (firebase.auth().currentUser != null) {
      getParticipantByUid(firebase.auth().currentUser.uid).then((doc) => {
        setGender(doc[0].data.gender);
        setAgeGroup(doc[0].data.ageGroup);
        setEducationLevel(doc[0].data.educationGroup);
        setPid(doc[0].id);
        setDoesDocExist(true);
      });
    }
  }, []);

  useEffect(() => {
    setshowGender(property.showGender);
    setshowAgeGroup(property.showAgeGroup);
    setshowEducationLevel(property.showEducationLevel);
  }, [property.showGender, property.showAgeGroup, property.showEducationLevel]);

  function updateProfile() {
    const newParticipantRef = db.collection("profiles").doc(pid);
    var newParticipantGender = gender;
    var newParticipantAgeGroup = ageGroup;
    var newParticipantEducationGroup = educationLevel;

    newParticipantRef
      .update({
        gender: newParticipantGender,
        ageGroup: newParticipantAgeGroup,
        educationGroup: newParticipantEducationGroup,
      })
      .then(() => {
        console.log("Profile updated");
        property.toHideOverlay();
      })
      .catch(function (error) {
        console.log("Something went wrong");
        // Some error occurred, you can inspect the code: error.code
        // Common errors could be invalid email and invalid or expired OTPs.
      });
  }

  const onGenderChange = (e) => {
    setGender(e.target.value);
  };

  const onAgeChange = (e) => {
    setAgeGroup(e.target.value);
  };

  const onEducationChange = (e) => {
    setEducationLevel(e.target.value);
  };

  if (!doesDocExist) {
    return null;
  }

  return (
    <OverLay>
      <OverLayContainer>
        <HeaderContainer>
          <NavMenu>
            <MenuIcon
              src={allIcons.closeIcon}
              onClick={property.toHideOverlay}
              alt="close"
            />
          </NavMenu>
        </HeaderContainer>
        <ContentWrapper>
          <h1>Update details</h1>
          <FormContainer>
            <ProfileBuilder
              isGenderNeeded={showGender}
              isAgeNeeded={showAgeGroup}
              isEduNeeded={showEducationLevel}
              gender={gender}
              onGenderChange={onGenderChange}
              // genderError={genderError}
              ageGroup={ageGroup}
              onAgeChange={onAgeChange}
              // ageGroupError={ageGroupError}
              educationLevel={educationLevel}
              onEducationChange={onEducationChange}
              // educationLevelError={educationLevelError}
            />
          </FormContainer>
          <FormNavigationComponent
            primaryLabel="Save"
            secondaryLabel="Cancel"
            primary={updateProfile}
            // secondary={back}
          />
        </ContentWrapper>
      </OverLayContainer>
    </OverLay>
  );
}
