import React, { useEffect } from "react";
import { useHistory, useLocation } from "react-router-dom";
import styled from "styled-components";
import firebase from "firebase/app";
import StyledFirebaseAuth from "react-firebaseui/StyledFirebaseAuth";
import "firebase/auth";
import Cookies from "js-cookie";
import { FormNotificationComponent } from "../atoms/FormNotification";

const Content = styled.div`
  width: 100%;
  display: flex;
  justify-content: center;
  padding-bottom: 32px;
`;

const Container = styled.div`
  @media (min-width: 449px) {
    margin-top: 70px;
    margin-bottom: 0px;
    width: 100%;
    height: 100%;
    margin-left: 16px;
    margin-right: 16px;
    max-width: 992px;
    height: fit-content;
    display: flex;
    flex-direction: column;
    justify-content: center;
  }

  @media (max-width: 448px) {
    margin-top: 70px;
    margin-bottom: 0px;
    margin-left: 16px;
    margin-right: 16px;
    width: 100%;
    height: 100%;
    max-width: 992px;
    height: fit-content;
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    justify-content: center;
  }
`;

const Section = styled.div`
  width: 100%;
  height: 40vh;
  display: flex;
  align-items: center;
  justify-content: center;
`;

export function MidSignUp() {
  let location = useLocation();
  let history = useHistory();

  useEffect(() => {
    const unregisterAuthObserver = firebase
      .auth()
      .onAuthStateChanged((user) => {
        // Cookies.set("isLoggedIn", "true", { sameSite: "strict" });
      });
    return () => unregisterAuthObserver(); // Make sure we un-register Firebase observers when the component unmounts.
  }, []);

  firebase.auth().onAuthStateChanged(function (user) {
    Cookies.set("isLoggedIn", "true", { sameSite: "strict" });
    if (user) {
      history.push(
        `/extra-info/${window.localStorage.getItem("lastQuestion")}`,
        {
          answer: location.state.answer,
        }
      );
      window.scrollTo(0, 0);
    }
  });

  // Configure FirebaseUI.
  const uiConfig = {
    // Popup signin flow rather than redirect flow.
    signInFlow: "popup",

    // Redirect to /signedIn after sign in is successful. Alternatively you can provide a callbacks.signInSuccess function.
    // signInSuccessUrl: "/signedIn",
    // We will display Google and Facebook as auth providers.
    signInOptions: [
      {
        provider: firebase.auth.GoogleAuthProvider.PROVIDER_ID,
      },
      {
        provider: firebase.auth.FacebookAuthProvider.PROVIDER_ID,
        customParameters: {
          display: "popup",
        },
      },
      {
        provider: firebase.auth.EmailAuthProvider.PROVIDER_ID,
        requireDisplayName: false,
      },
    ],
  };

  return (
    <Content>
      <Container>
        <FormNotificationComponent
          type="info"
          header="You need to sign in first"
          body="Don't worry if you don't have an account. You can still sign in using the options below and we will create an account for you."
        />
        <Section>
          <StyledFirebaseAuth
            uiConfig={uiConfig}
            firebaseAuth={firebase.auth()}
          />
        </Section>
      </Container>
    </Content>
  );
}
