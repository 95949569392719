import React from "react";
import styled, { keyframes } from "styled-components";

const grow = keyframes`
0%   { height: 1px;}
50% { height: 24px;}
100% { height: 1px;}
`;

const SingleBar1 = styled.div`
  width: 3px;
  height: 1px;
  margin-left: 2px;
  background-color: white;
  animation-name: ${grow};
  animation-duration: 800ms;
  animation-iteration-count: infinite;
`;

const SingleBar2 = styled.div`
  width: 3px;
  height: 1px;
  margin-left: 3px;
  background-color: white;
  animation-name: ${grow};
  animation-duration: 800ms;
  animation-iteration-count: infinite;
  animation-delay: 200ms;
`;

const SingleBar3 = styled.div`
  width: 3px;
  height: 1px;
  margin-left: 3px;
  background-color: white;
  animation-name: ${grow};
  animation-duration: 800ms;
  animation-iteration-count: infinite;
  animation-delay: 400ms;
`;

const Container = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  height: 24px;
  width: 44px;
  height: 24px;
`;

export function Loader() {
  return (
    <Container>
      <SingleBar1 />
      <SingleBar2 />
      <SingleBar3 />
    </Container>
  );
}
