import { db } from "../xyzFirebase/firebase";
import firebase from "firebase/app";
import Cookies from "js-cookie";

// // Make a request for a user with a given ID

//Returns participant data by ID
export function getParticipantById(id) {
  return db
    .collection("profiles")
    .doc(id)
    .get()
    .then((doc) => {
      if (doc.exists) {
        return doc.data();
      } else {
        return undefined;
      }
    });
}

export function getParticipantByUid(uid) {
  return db
    .collection("profiles")
    .where("uid", "==", uid)
    .get()
    .then((querySnapshot) => {
      return querySnapshot.docs.map((doc) => {
        if (doc.exists) {
          return {
            data: doc.data(),
            id: doc.id,
          };
        } else {
          return null;
        }
      });
    });
}

export function getQuestionDataById(id) {
  return db
    .collection("questions-basic")
    .doc(id)
    .get()
    .then((doc) => {
      if (doc.exists) {
        return doc.data();
      } else {
        return undefined;
      }
    });
}

export function getQuestionDataSummaryById(id) {
  return db
    .collection("homeScreen")
    .doc("first100")
    .get()
    .then((doc) => {
      if (doc.exists) {
        return doc.data()[id];
      } else {
        return undefined;
      }
    });
}

// export function getQuestionDataById(id) {
//   return db
//     .collection("questions-basic")
//     .doc(id)
//     .onSnapshot(function (doc) {
//       var source = doc.metadata.hasPendingWrites ? "Local" : "Server";
//       return doc.data();
//     });
// }

export function getQuestionIds() {
  return db
    .collection("homeScreen")
    .doc("list")
    .get()
    .then((doc) => {
      return doc.data().list;
    });
}

export async function updateData(
  participantId,
  ageGroup,
  eduLevel,
  gender,
  questionId,
  doesDataExist,
  response
) {
  const newParticipantRef = db.collection("profiles");

  const questionRef = db.collection("questions-basic").doc(questionId);

  const questionSummaryRef = db.collection("homeScreen").doc("first100");

  const authID = firebase.auth().currentUser.uid;

  var response1Addition;

  var response2Addition;

  if (response === 1) {
    response1Addition = 1;
    response2Addition = 0;
  } else {
    response1Addition = 0;
    response2Addition = 1;
  }

  await db.runTransaction(async (t) => {
    if (firebase.auth().currentUser !== null && doesDataExist) {
      const participantRef = db.collection("profiles").doc(participantId);
      const responseCountType = `response${response}Count`;
      const ageGroupReponse = `ageGroup${ageGroup}ResponseCount`;
      const ageGroupReponseType = `ageGroup${ageGroup}Response${response}Count`;
      const eduLevelReponse = `eduLevel${eduLevel}ResponseCount`;
      const eduLevelReponseType = `eduLevel${eduLevel}Response${response}Count`;
      const genderReponse = `${gender}ResponseCount`;
      const genderReponseType = `${gender}Response${response}Count`;
      const doc = await t.get(questionRef);

      await t.update(participantRef, {
        [questionId]: response,
      });

      await t.update(questionRef, {
        responseCount: doc.data().responseCount + 1,
        [responseCountType]: doc.data()[responseCountType] + 1,
        [ageGroupReponse]: doc.data()[ageGroupReponse] + 1,
        [ageGroupReponseType]: doc.data()[ageGroupReponseType] + 1,
        [eduLevelReponse]: doc.data()[eduLevelReponse] + 1,
        [eduLevelReponseType]: doc.data()[eduLevelReponseType] + 1,
        [genderReponse]: doc.data()[genderReponse] + 1,
        [genderReponseType]: doc.data()[genderReponseType] + 1,
      });

      await t.update(questionSummaryRef, {
        [questionId]: {
          responseCount: doc.data().responseCount + 1,
          [responseCountType]: doc.data()[responseCountType] + 1,
          questionCopy: doc.data().questionCopy,
          questionContext: doc.data().questionContext,
          response1Copy: doc.data().response1Copy,
          response2Copy: doc.data().response2Copy,
          response1Count: doc.data().response1Count + response1Addition,
          response2Count: doc.data().response2Count + response2Addition,
          shoutOut: doc.data().shoutOut,
          imageRef: doc.data().imageRef,
        },
      });

      console.log("Particpant updated");

      Cookies.set("pid", participantId, { sameSite: "strict" });
    } else if (firebase.auth().currentUser !== null) {
      //Creating new Participant ID
      const responseCountType = `response${response}Count`;
      const ageGroupReponse = `ageGroup${ageGroup}ResponseCount`;
      const ageGroupReponseType = `ageGroup${ageGroup}Response${response}Count`;
      const eduLevelReponse = `eduLevel${eduLevel}ResponseCount`;
      const eduLevelReponseType = `eduLevel${eduLevel}Response${response}Count`;
      const genderReponse = `${gender}ResponseCount`;
      const genderReponseType = `${gender}Response${response}Count`;
      const doc = await t.get(questionRef);

      await t.update(questionRef, {
        responseCount: doc.data().responseCount + 1,
        [responseCountType]: doc.data()[responseCountType] + 1,
        [ageGroupReponse]: doc.data()[ageGroupReponse] + 1,
        [ageGroupReponseType]: doc.data()[ageGroupReponseType] + 1,
        [eduLevelReponse]: doc.data()[eduLevelReponse] + 1,
        [eduLevelReponseType]: doc.data()[eduLevelReponseType] + 1,
        [genderReponse]: doc.data()[genderReponse] + 1,
        [genderReponseType]: doc.data()[genderReponseType] + 1,
      });

      await t.update(questionSummaryRef, {
        [questionId]: {
          responseCount: doc.data().responseCount + 1,
          [responseCountType]: doc.data()[responseCountType] + 1,
          questionCopy: doc.data().questionCopy,
          questionContext: doc.data().questionContext,
          response1Copy: doc.data().response1Copy,
          response2Copy: doc.data().response2Copy,
          response1Count: doc.data().response1Count + response1Addition,
          response2Count: doc.data().response2Count + response2Addition,
          shoutOut: doc.data().shoutOut,
          imageRef: doc.data().imageRef,
        },
      });

      newParticipantRef
        .add({
          ageGroup: ageGroup,
          educationGroup: eduLevel,
          gender: gender,
          uid: authID,
          [questionId]: response,
        })
        .then((docRef) => {
          console.log("Document written with ID: ", docRef.id);
        });

      console.log("Particpant added");
    }
  });

  console.log("Transaction success!");
}
