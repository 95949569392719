import React from "react";
import styled from "styled-components";

const Container = styled.div`
  width: 100%;
  display: flex;
  flex-direction: column;
  margin-top: 16px;
  margin-bottom: 16px;
`;

const LabelWrapper = styled.div`
  width: 100%;
  display: flex;
  flex-direction: row;
`;

const LabelWrapperLeft = styled.div`
  width: 60%;
  display: flex;
  justify-content: flex-start;
`;

const LabelWrapperRight = styled.div`
  width: 40%;
  display: flex;
  justify-content: flex-end;
`;

const BarChartMax = styled.div`
  width: 100%;
  height: 40px;
  background-color: var(--lightest-grey);
  display: flex;
  justify-content: flex-start;
`;

const BarChartVariable = styled.div`
  width: calc(100% * ${simplifiedPercentage});
  height: 40px;
  background-color: var(--primary-color);
`;

var yesPercentage;

function simplifiedPercentage() {
  return yesPercentage / 100;
}

export function SingleBarComponent(property) {
  yesPercentage = property.yesPercentage;

  function checkData() {
    if (yesPercentage !== "No data" && yesPercentage >= 1) {
      return <label>{yesPercentage}%</label>;
    } else {
      return <label> {"Less than 1%"}</label>;
    }
  }

  return (
    <Container>
      <LabelWrapper>
        <LabelWrapperLeft>
          <label>{property.label}</label>
        </LabelWrapperLeft>
        <LabelWrapperRight>{checkData()}</LabelWrapperRight>
      </LabelWrapper>
      <BarChartMax>
        <BarChartVariable />
      </BarChartMax>
    </Container>
  );
}
