import React, { useState, useEffect } from "react";
import { FormNotificationComponent } from "../atoms/FormNotification";
import { ProfileBuilder } from "../organisms/ProfileBuilder";
import { FormNavigationComponent } from "../molecules/FormNavigation";
import styled from "styled-components";
import { useHistory, useParams, useLocation } from "react-router-dom";
import firebase from "firebase/app";
import "firebase/auth";
import { getParticipantByUid } from "../xyzFirebase/firebaseQueries";
import { updateData } from "../xyzFirebase/firebaseQueries";

const Content = styled.div`
  width: 100%;
  display: flex;
  justify-content: center;
  padding-bottom: 32px;
`;

const Container = styled.div`
  @media (min-width: 449px) {
    margin-top: 70px;
    margin-bottom: 0px;
    width: 100%;
    height: 100%;
    margin-left: 16px;
    margin-right: 16px;
    max-width: 992px;
    height: fit-content;
    display: flex;
    flex-direction: column;
    justify-content: center;
  }

  @media (max-width: 448px) {
    margin-top: 70px;
    margin-bottom: 0px;
    margin-left: 16px;
    margin-right: 16px;
    width: 100%;
    height: 100%;
    max-width: 992px;
    height: fit-content;
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    justify-content: center;
  }
`;

const Summary = styled.p``;

export function ExtraInformationForm(properties) {
  const [gender, setGender] = useState();
  const [ageGroup, setAgeGroup] = useState();
  const [educationLevel, setEducationLevel] = useState();
  const [missingDetailsError, setMissingDetailsError] = useState(false);

  //States for updating participant data

  const [participantId, setParticipantId] = useState(undefined);
  const [participantGender, setParticipantGender] = useState(undefined);
  const [participantAgeGroup, setParticipantAgeGroup] = useState(undefined);
  const [participantEduLevel, setParticipantEduLevel] = useState(undefined);
  const [doesDataExist, setDoesDataExist] = useState(false);
  const [questionAnswered, setQuestionAnswered] = useState(false);
  const [loadingState, setLoadingState] = useState(false);

  //What data is needed?
  const [isGenderNeeded, setIsGenderNeeded] = useState(true);
  const [isAgeNeeded, setIsAgeNeeded] = useState(true);
  const [isEduNeeded, setIsEduNeeded] = useState(true);

  let history = useHistory();
  let params = useParams();
  let location = useLocation();

  useEffect(() => {
    if (location.state === undefined) {
      history.push(`/question/${params.questionid}`);
      window.scrollTo(0, 0);
    }
  });

  useEffect(() => {
    if (firebase.auth().currentUser !== null) {
      getParticipantByUid(firebase.auth().currentUser.uid).then((doc) => {
        if (doc.length !== 0) {
          if (doc[0].id !== undefined) {
            setParticipantId(doc[0].id);
          }
          if (doc[0].data.gender !== undefined) {
            setParticipantGender(doc[0].data.gender);
            setIsGenderNeeded(false);
          }
          if (doc[0].data.ageGroup !== undefined) {
            setParticipantAgeGroup(doc[0].data.ageGroup);
            setIsAgeNeeded(false);
          }
          if (doc[0].data.educationGroup !== undefined) {
            setParticipantEduLevel(doc[0].data.educationGroup);
            setIsEduNeeded(false);
          }
          if (
            doc[0].data.gender !== undefined &&
            doc[0].data.ageGroup !== undefined &&
            doc[0].data.educationGroup !== undefined
          ) {
            setDoesDataExist(true);
          }
        }
      }, []);
    }
  });

  useEffect(() => {
    if (firebase.auth().currentUser !== null) {
      getParticipantByUid(firebase.auth().currentUser.uid).then((doc) => {
        if (doc.length !== 0) {
          if (doc[0].data[params.questionid] !== undefined) {
            console.log("question answered");
            setQuestionAnswered(true);
          }
        }
      });
    }
  }, [params.questionid]);

  window.localStorage.removeItem("lastQuestion");
  window.localStorage.removeItem("QuestionCopy");

  //Function to update database

  //  participantId,
  //  ageGroup,
  //  educationLevel,
  //  gender,
  //  questionid,
  //  doesDataExist,
  //  response

  async function updateQuestion() {
    if (questionAnswered === false) {
      if (firebase.auth().currentUser !== null && doesDataExist) {
        updateData(
          participantId,
          participantAgeGroup,
          participantEduLevel,
          participantGender,
          params.questionid,
          doesDataExist,
          location.state.answer
        );
      } else if (firebase.auth().currentUser !== null) {
        updateData(
          undefined,
          ageGroup,
          educationLevel,
          gender,
          params.questionid,
          doesDataExist,
          location.state.answer
        );
      }
    }
  }

  function goHome() {
    history.push("/home");
    window.scrollTo(0, 0);
  }

  function loading() {
    if (loadingState) {
      return true;
    }
  }

  function handleClick() {
    if (questionAnswered) {
      history.push(`/results/${params.questionid}`);
      window.scrollTo(0, 0);
    } else if (
      isGenderNeeded === false &&
      isAgeNeeded === false &&
      isEduNeeded === false
    ) {
      updateQuestion()
        .then(() => {
          //code before the pause
          setLoadingState(true);

          setTimeout(function () {
            console.log("Transaction success!");
            history.push(`/results/${params.questionid}`);
            window.scrollTo(0, 0);
          }, 2000);
        })
        .catch((e) => {
          console.log("Update failed");
          console.log(e);
        });
    } else if (!gender || !ageGroup || !educationLevel) {
      setMissingDetailsError(true);
      window.scroll({
        top: 0,
        behavior: "smooth",
      });
    } else {
      updateQuestion()
        .then(() => {
          //code before the pause
          setLoadingState(true);

          setTimeout(function () {
            console.log("Transaction success!");
            history.push(`/results/${params.questionid}`);
            window.scrollTo(0, 0);
          }, 2000);
        })
        .catch((e) => {
          console.log("Update failed");
          console.log(e);
        });
    }
  }

  const onGenderChange = (e) => {
    setGender(e.target.value);
    setMissingDetailsError(false);
  };

  const onAgeChange = (e) => {
    setAgeGroup(e.target.value);
    setMissingDetailsError(false);
  };

  const onEducationChange = (e) => {
    setEducationLevel(e.target.value);
    setMissingDetailsError(false);
  };

  function moreInfoContext() {
    if (isGenderNeeded && isAgeNeeded && isEduNeeded) {
      return (
        <Summary>
          We would love to show you what others think. But before we do, can you
          tell us a bit about yourself?
        </Summary>
      );
    } else return <></>;
  }

  function onFormsubmission() {
    if (missingDetailsError === true) {
      return (
        <FormNotificationComponent
          type="error"
          header="We need a bit more from you"
          body="We need this information before we can show you the results. Dont worry,
          none of it can be used to identify you."
        />
      );
    } else {
      return null;
    }
  }

  return (
    <Content>
      <Container>
        {onFormsubmission()}
        <h1>Thank you for giving us your view!</h1>
        {moreInfoContext()}
        <ProfileBuilder
          isGenderNeeded={isGenderNeeded}
          isAgeNeeded={isAgeNeeded}
          isEduNeeded={isEduNeeded}
          gender={gender}
          onGenderChange={onGenderChange}
          // genderError={genderError}
          ageGroup={ageGroup}
          onAgeChange={onAgeChange}
          // ageGroupError={ageGroupError}
          educationLevel={educationLevel}
          onEducationChange={onEducationChange}
          // educationLevelError={educationLevelError}
        />
        <FormNavigationComponent
          primaryLabel="Show me the results"
          secondaryLabel="I've changed my mind"
          primary={handleClick}
          loading={loading()}
          secondary={goHome}
        />
      </Container>
    </Content>
  );
}
