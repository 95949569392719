import React, { useEffect, useState } from "react";
import styled from "styled-components";
import { useHistory } from "react-router-dom";
import { thousandSeparator } from "../Utilities/maths";
import { getParticipantById } from "../xyzFirebase/firebaseQueries";
import { getParticipantByUid } from "../xyzFirebase/firebaseQueries";
// import { highestPercentageCalculation } from "../calculations";
// import { popularOutlookCalculation } from "../calculations";
import cameraIcon from "../xyz-svgs/camera-icon.svg";
import firebase from "firebase/app";
import "firebase/auth";

const Container = styled.div`
  width: 100%;
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  align-items: flex-start padding 16px;

  cursor: pointer;
`;

const ImageBox = styled.div`
  position: relative;
  background-color: var(--light-grey);
  width: 100%;
  padding-top: 50%;
  margin-bottom: 4px;
`;

const PlaceHolder = styled.img`
  position: absolute;
  top: calc(50% - 16px);
  left: calc(50% - 16px);
  z-index: 1;
`;

const Image = styled.img`
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  z-index: 2;
`;

const Question = styled.h3`
  margin-bottom: 8px;
  margin-top: 0;
  @media (min-width: 769px) {
    margin-bottom: 16px;
    height: 44px;
    font-size: 18px;
    line-height: 27px;
  }
`;

const Stat = styled.h4`
  margin: 0;
  padding-top: 8px;
  padding-bottom: 8px;
  box-sizing: border-box;
  -moz-box-sizing: border-box;
  -webkit-box-sizing: border-box;

  border-bottom: solid;
  border-top: solid;
  border-width: 1px;
  border-color: var(--light-grey);
  background-color: #fdfdfd;
`;

export function SingleQuestionComponent(properties) {
  const [PaticipantId, setParticipantId] = useState(undefined);
  const [questionAnswered, setQuestionAnswered] = useState(false);
  // const [highestPercentage, setHighestPercentage] = useState();
  // const [popularOutlook, setPopularOutlook] = useState();

  useEffect(() => {
    if (firebase.auth().currentUser !== null) {
      getParticipantByUid(firebase.auth().currentUser.uid).then((doc) => {
        if (doc.length !== 0) {
          if (doc[0].id !== undefined) {
            setParticipantId(doc[0].id);
          }
        }
      });
    }
  }, []);

  useEffect(() => {
    if (firebase.auth().currentUser !== null) {
      getParticipantByUid(firebase.auth().currentUser.uid).then((doc) => {
        if (doc.length !== 0) {
          if (doc[0].data[properties.id] !== undefined) {
            setQuestionAnswered(true);
          }
        }
      });
    }
  }, [properties.id]);

  // useEffect(() => {
  //   setHighestPercentage(
  //     highestPercentageCalculation(
  //       properties.question.response1Count,
  //       properties.question.response2Count,
  //       properties.question.responseCount
  //     )
  //   );
  // }, [
  //   properties.question.response1Count,
  //   properties.question.response2Count,
  //   properties.question.responseCount,
  // ]);

  // useEffect(() => {
  //   setPopularOutlook(
  //     popularOutlookCalculation(
  //       properties.question.response1Count,
  //       properties.question.response2Count,
  //       properties.question.response1Copy,
  //       properties.question.response2Copy
  //     )
  //   );
  // }, [
  //   properties.question.response1Copy,
  //   properties.question.response1Count,
  //   properties.question.response2Copy,
  //   properties.question.response2Count,
  // ]);

  let history = useHistory();

  function handleClick(id) {
    if (PaticipantId !== undefined) {
      getParticipantById(PaticipantId).then((data) => {
        if (data !== undefined) {
          if (data[id] !== undefined) {
            history.push(`/results/${id}`);
            window.scrollTo(0, 0);
          } else {
            history.push(`/question/${id}`);
            window.scrollTo(0, 0);
          }
        } else {
          history.push(`/question/${id}`);
          window.scrollTo(0, 0);
        }
      });
    } else {
      history.push(`/question/${id}`);
      window.scrollTo(0, 0);
    }
  }

  function statContent() {
    if (properties.question.responseCount >= 5) {
      return (
        <em>{thousandSeparator(properties.question.responseCount)} answers</em>
        // <em>
        //   {highestPercentage}% say {popularOutlook}
        // </em>
      );
    }
    if (questionAnswered && properties.question.responseCount < 5) {
      return <em>Waiting for more results</em>;
    } else {
      return <em>New!</em>;
    }
  }

  return (
    <Container onClick={() => handleClick(properties.id)}>
      <ImageBox>
        <PlaceHolder src={cameraIcon} alt={"Photo placeholder"} />
        <Image
          src={properties.question.imageRef}
          alt={properties.question.questionCopy}
        />
      </ImageBox>
      <Question>{properties.question.questionCopy}</Question>

      <Stat>{statContent()}</Stat>
    </Container>
  );
}
