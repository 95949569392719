import app from "firebase/app";
import "firebase/firestore";

const config = {
  apiKey: "AIzaSyDTk4a4Hy5nKkYZ6okavCoYO8QKTwlc9II",
  authDomain: "loopsage.com",
  databaseURL: "https://loopsage-4c82d.firebaseio.com",
  projectId: "loopsage-4c82d",
};

app.initializeApp(config);
export var db = app.firestore();
