import React, { useEffect, useState } from "react";
import { BrowserRouter as Router } from "react-router-dom";
import { Switch, Route, Redirect } from "react-router-dom";
import "./App.css";
import "./xyz-css/fonts.css";
// import "./xyz-css/firebaseui-styling.global.css"; // Import globally. Not with CSS modules.
import styled from "styled-components";
import { AppHeader } from "./organisms/AppHeader";
import { MenuOverlay } from "./organisms/MenuOverlay";
import { AppFooter } from "./organisms/AppFooter";
import { HomeContent } from "./templates/HomeContent";
import { Question } from "./templates/Question";
import { ExtraInformationComponent } from "./templates/ExtraInformation";
import { AboutUs } from "./templates/AboutUs";
import { ContactUs } from "./templates/ContactUs";
import { PrivacyPolicy } from "./templates/PrivacyPolicy";
import { LogIn } from "./templates/LogIn";
import { Account } from "./templates/Account";
import { getQuestionIds } from "./xyzFirebase/firebaseQueries";
import { ResultsComponent } from "./templates/Results";
import { MidSignUp } from "./templates/SignUpMidJourney";
import { NewQuestionForm } from "./templates/NewQuestionForm";
import "firebase/auth";
import firebase from "firebase/app";
import Cookies from "js-cookie";

import { Helmet, HelmetProvider } from "react-helmet-async";

const AppContainer = styled.div`
  position: relative;
  top: 0;
  left: 0;
  height: fit-content;
  width: 100%;
  display: flex;
  flex-direction: column;
  justify-content: center;
`;

function App() {
  const [overlayVisibility, setOverlayVisibility] = useState(false);
  const [questionId, setQuestionId] = useState();
  const [isLoggedIn, setIsLoggedIn] = useState(false);

  useEffect(() => {
    getQuestionIds().then((data) => {
      setQuestionId(data);
    });
  }, []);

  useEffect(() => {
    if (Cookies.get("isLoggedIn") === "true") {
      setIsLoggedIn(true);
    }
  }, []);

  function hasLoggedIn() {
    if (firebase.auth().currentUser !== null) {
      return setIsLoggedIn(true);
    }
    setIsLoggedIn(false);
  }

  firebase.auth().onAuthStateChanged(function (user) {
    if (user) {
      setIsLoggedIn(true);
    } else {
      setIsLoggedIn(false);
    }
  });

  function showOverlay() {
    setOverlayVisibility(true);
  }

  function hideOverlay() {
    setOverlayVisibility(false);
  }

  function onShowOveray() {
    if (overlayVisibility === true) {
      return (
        <MenuOverlay toHideOverlay={hideOverlay} hasLoggedIn={isLoggedIn} />
      );
    } else {
      return null;
    }
  }
  if (!questionId) {
    return null;
  }
  return (
    <HelmetProvider>
      <AppContainer>
        <Helmet>
          <meta
            name="description"
            content="We’re on a mission to find out what world really thinks. Join us on the journey."
          />
          <title>loopsage</title>
        </Helmet>
        <Router>
          {onShowOveray()}
          <AppHeader toShowOverlay={showOverlay} hasLoggedIn={isLoggedIn} />
          <Switch>
            <Route
              path="/home"
              render={() => {
                return <HomeContent />;
              }}
            />
            <Route
              path="/question/:questionid"
              render={(properties) => {
                if (
                  questionId.indexOf(properties.match.params.questionid) !== -1
                ) {
                  return <Question id={properties.match.params.questionid} />;
                }
                return <Redirect to="/home" />;
              }}
            />
            <Route
              path="/extra-info/:questionid"
              render={(properties) => {
                if (
                  questionId.indexOf(properties.match.params.questionid) !== -1
                ) {
                  return (
                    <ExtraInformationComponent
                      id={properties.match.params.questionid}
                    />
                  );
                }
                return <Redirect to="/home" />;
              }}
            />

            <Route
              path="/results/:questionid"
              render={(properties) => {
                if (
                  questionId.indexOf(properties.match.params.questionid) !== -1
                ) {
                  return (
                    <ResultsComponent id={properties.match.params.questionid} />
                  );
                }
                return <Redirect to="/home" />;
              }}
            />

            <Route path="/about-us">
              <AboutUs />
            </Route>
            <Route path="/contact-us">
              <ContactUs />
            </Route>
            <Route path="/privacy-policy">
              <PrivacyPolicy />
            </Route>
            <Route path="/log-in">
              <LogIn />
            </Route>

            <Route path="/mid-sign-up">
              <MidSignUp />
            </Route>
            <Route path="/account">
              <Account
                hasLoggedIn={isLoggedIn}
                hasLoggedInFunction={hasLoggedIn}
              />
            </Route>
            <Route path="/addNewQuestion">
              <NewQuestionForm />
            </Route>
            <Route path="/">
              <Redirect to="/home" />
            </Route>
          </Switch>
          <AppFooter />
        </Router>
      </AppContainer>
    </HelmetProvider>
  );
}

export default App;
