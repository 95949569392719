import React from "react";
import styled from "styled-components";
import ".././App.css";
import { ButtonComponent } from "../atoms/Button";

const NavigationWrapper = styled.div`
  box-sizing: border-box;
  -moz-box-sizing: border-box;
  -webkit-box-sizing: border-box;
  border-top-style: solid;
  border-color: var(--light-grey);
  border-width: 1px;
  padding-top: 32px;
  display: flex;

  @media (max-width: 448px) {
    width: 100%;
    flex-direction: column-reverse;
    justify-content: center;
  }
`;

const NavigationWrapperRight = styled.div`
  @media (min-width: 449px) {
    display: flex;
    justify-content: flex-end;
    width: 50%;
    margin-left: 8px;
  }
`;

const NavigationWrapperLeft = styled.div`
  @media (min-width: 449px) {
    display: flex;
    justify-content: flex-start;
    width: 50%;
    margin-right: 8px;
  }
`;

export function FormNavigationComponent(property) {
  return (
    <NavigationWrapper>
      <NavigationWrapperLeft>
        <ButtonComponent
          text={property.secondaryLabel}
          level="secondary"
          onClick={property.secondary}
        />
      </NavigationWrapperLeft>
      <NavigationWrapperRight>
        <ButtonComponent
          text={property.primaryLabel}
          level={property.primaryLevel}
          onClick={property.primary}
          loading={property.loading}
        />
      </NavigationWrapperRight>
    </NavigationWrapper>
  );
}
