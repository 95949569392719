import React, { useState, useEffect } from "react";
import { RadioButtonGroup } from "../molecules/RadioButtonGroup";
import styled from "styled-components";

const ProfileBuilderContainer = styled.div`
  margin-top: 16px;
  width: 100%;
`;

export function ProfileBuilder(properties) {
  const [isGenderNeeded, setIsGenderNeeded] = useState(Boolean);
  const [isAgeNeeded, setIsAgeNeeded] = useState(Boolean);
  const [isEduNeeded, setIsEduNeeded] = useState(Boolean);

  useEffect(() => {
    setIsGenderNeeded(properties.isGenderNeeded);
    setIsAgeNeeded(properties.isAgeNeeded);
    setIsEduNeeded(properties.isEduNeeded);
  }, [
    properties.isGenderNeeded,
    properties.isAgeNeeded,
    properties.isEduNeeded,
  ]);

  function CheckGender() {
    if (isGenderNeeded) {
      return (
        <RadioButtonGroup
          value={properties.gender}
          question="How would your define your gender?"
          group="gender"
          onChange={properties.onGenderChange}
          errorState={properties.genderError}
          ErrorMessage="We need this to display results by gender"
          options={[
            { value: "male", label: "Male" },
            { value: "female", label: "Female" },
            { value: "non-binary", label: "Non Binary" },
          ]}
        />
      );
    }
  }
  function CheckAge() {
    if (isAgeNeeded) {
      return (
        <RadioButtonGroup
          value={properties.ageGroup}
          question="What's your age group"
          group="age-group"
          onChange={properties.onAgeChange}
          errorState={properties.ageGroupError}
          ErrorMessage="We need this to display results by age group"
          options={[
            { value: "1", label: "18-25 years old" },
            { value: "2", label: "26-30 years old" },
            { value: "3", label: "31-39 years old" },
            { value: "4", label: "40+" },
          ]}
        />
      );
    }
  }
  function CheckEducation() {
    if (isEduNeeded) {
      return (
        <RadioButtonGroup
          value={properties.educationLevel}
          question="What’s your highest level of education?"
          group="education-level"
          onChange={properties.onEducationChange}
          errorState={properties.educationLevelError}
          ErrorMessage="We need this to display results by education level"
          options={[
            { value: "D", label: "Secondary school" },
            { value: "C", label: "Sixth form / College" },
            { value: "B", label: "Bachelor's degree" },
            { value: "A", label: "Masters degree / PhD" },
          ]}
        />
      );
    }
  }

  return (
    <ProfileBuilderContainer>
      {CheckGender()}
      {CheckAge()}
      {CheckEducation()}
    </ProfileBuilderContainer>
  );
}
