import React from "react";
import styled from "styled-components";
import { RadioButtonComponent } from "../atoms/RadioButton";

const RadioButtonWrapper = styled.div`
  display: flex;
  flex-direction: column;
  width: 100%;
  max-width: 448px;
  margin-bottom: 16px;
`;

const Quesion = styled.label`
  color: #000;
  margin-bottom: 0;
`;

const ErrorText = styled.p`
  color: var(--primary-color);
  margin: 0;
`;

const RadioButtons = styled.div`
  margin-top: 8px;
`;

export function RadioButtonGroup(property) {
  function showinlineError() {
    if (property.errorState === true) {
      return <ErrorText>{property.ErrorMessage}</ErrorText>;
    } else {
      return null;
    }
  }

  const options = property.options;

  return (
    <RadioButtonWrapper>
      <Quesion>{property.question}</Quesion>
      {showinlineError()}
      <RadioButtons>
        {options.map((option) => {
          return (
            <RadioButtonComponent
              checked={property.value === option.value}
              id={option.value}
              group={property.group}
              label={option.label}
              value={option.value}
              onChange={property.onChange}
              key={`${property.group}-${option.label}`}
            />
          );
        })}
      </RadioButtons>
    </RadioButtonWrapper>
  );
}
