import React from "react";
import styled from "styled-components";

const Wrapper = styled.div`
  margin-top: 16px;
`;

const Label = styled.label`
  font-size: 16px;
`;

const HintText = styled.p`
  width: 100%;
  margin: 0;
`;

const ErrorText = styled.p`
  color: var(--primary-color);
  margin: 0;
`;

const Input = styled.input`
  -webkit-appearance: none;
  -moz-appearance: none;
  appearance: none;

  margin-top: 8px;
  width: 100%;
  height: 48px;
  padding-left: 16px;
  padding-right: 16px;
  border: 1px solid;
  border-radius: 0;

  font-family: "Open Sans", sans-serif;
  font: weight 400;
  line-height: 22px;
  color: #000;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;

  font-size: 16px;

  ${borderColour}
`;

function borderColour(property) {
  if (property.errorState === true) {
    return "border-color: var(--primary-color);";
  } else {
    return "border-color: var(--grey);";
  }
}

export function TextInputComponent(property) {
  function showinlineError() {
    if (property.errorState === true) {
      return <ErrorText>{property.ErrorMessage}test</ErrorText>;
    } else {
      return null;
    }
  }

  return (
    <Wrapper>
      <Label>{property.label}</Label>
      {showinlineError()}
      <HintText></HintText>
      <Input
        id={property.id}
        type={property.type}
        name={property.name}
        onChange={property.onChange}
        onBlur={property.onBlur}
        onFocus={property.onFocus}
        errorState={property.errorState}
      />
    </Wrapper>
  );
}
