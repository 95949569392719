import React from "react";
import { BinaryQuestionComponent } from "../molecules/BinaryQuestion";
import { QuestionSummaryContainer } from "../organisms/QuestionSummaryContainer";
import styled from "styled-components";
// import { Helmet } from "react-helmet-async";

const Content = styled.div`
  width: 100%;
  display: flex;
  justify-content: center;
`;

const Container = styled.div`
  @media (min-width: 449px) {
    margin-top: 70px;
    margin-bottom: 0px;
    width: 100%;
    height: 100%;
    margin-left: 16px;
    margin-right: 16px;
    max-width: 992px;
    height: fit-content;
    display: flex;
    flex-direction: column;
    justify-content: center;
  }

  @media (max-width: 448px) {
    margin-top: 70px;
    margin-bottom: 0px;
    margin-left: 16px;
    margin-right: 16px;
    width: 100%;
    height: 100%;
    max-width: 992px;
    height: fit-content;
    display: flex;
    flconstex-direction: column;
    align-items: flex-start;
    justify-content: center;
  }
`;

export function Question(properties) {
  return (
    <Content>
      {/* <Helmet>
        <meta name="description" content="Tell us your view on this topic." />
        <title>loopsage</title>
      </Helmet> */}
      <Container>
        <QuestionSummaryContainer id={properties.id} key={properties.id}>
          {(question) => {
            return (
              <BinaryQuestionComponent id={properties.id} question={question} />
            );
          }}
        </QuestionSummaryContainer>
      </Container>
    </Content>
  );
}
